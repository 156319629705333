export class PaymentAvailabilityService {
    constructor() {
        // Initialize properties first
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.backendAvailable = true;
        this.checkInterval = null;
        this.STORAGE_KEY_PREFIX = 'pending-payment-';
        this.lastCheckTime = null;
        
        // Bind methods to ensure correct 'this' context
        this.checkBackendAvailability = this.checkBackendAvailability.bind(this);
        this.reconcilePendingTransactions = this.reconcilePendingTransactions.bind(this);
        
        // Initialize after methods are bound
        this.initializeBackendCheck();
    }
    async checkBackendAvailability() {
        try {
            const response = await fetch(`${this.baseUrl}/health`, {
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache'
                },
                credentials: 'include'
            });

            if (!response.ok) {
                console.warn('Backend health check failed:', response.status);
                return false;
            }

            const data = await response.json();
            this.lastCheckTime = new Date();
            
            return data.status === 'healthy';
        } catch (error) {
            console.error('Backend availability check failed:', error);
            return false;
        }
    }

    initializeBackendCheck() {
        // Initial check
        this.checkBackendAvailability().catch(error => {
            console.error('Initial backend check failed:', error);
            this.backendAvailable = false;
        });

        // Setup periodic check
        this.checkInterval = setInterval(async () => {
            try {
                const wasAvailable = this.backendAvailable;
                this.backendAvailable = await this.checkBackendAvailability();

                if (!wasAvailable && this.backendAvailable) {
                    await this.reconcilePendingTransactions();
                }

                if (wasAvailable !== this.backendAvailable) {
                    console.log(`Backend availability changed: ${this.backendAvailable}`);
                }
            } catch (error) {
                console.error('Background availability check failed:', error);
                this.backendAvailable = false;
            }
        }, 30000);
    }
    isBackendAvailable() {
        return this.backendAvailable;
    }

    getLastCheckTime() {
        return this.lastCheckTime;
    }

    getPendingTransactionCount() {
        return Object.keys(localStorage)
            .filter(key => key.startsWith(this.STORAGE_KEY_PREFIX))
            .length;
    }

    canProcessNewPayments() {
        // Only allow new payment if:
        // 1. Backend is available and no pending transactions, or
        // 2. Backend is unavailable and no pending transactions
        return this.getPendingTransactionCount() === 0;
    }

    storePendingTransaction(transactionData) {
        if (this.getPendingTransactionCount() > 0) {
            throw new Error('Cannot store new transaction while another is pending');
        }

        const key = `${this.STORAGE_KEY_PREFIX}${transactionData.transactionId}`;
        const userData = {
            userId: localStorage.getItem('userid'),
            userName: localStorage.getItem('userName')
        };

        localStorage.setItem(key, JSON.stringify({
            ...transactionData,
            ...userData,
            storedAt: new Date().toISOString()
        }));
    }

    async reconcilePendingTransactions() {
        const pendingKeys = Object.keys(localStorage)
            .filter(key => key.startsWith(this.STORAGE_KEY_PREFIX));

        for (const key of pendingKeys) {
            try {
                const transactionData = JSON.parse(localStorage.getItem(key));
                const reconciled = await this.reconcileTransaction(transactionData);
                
                if (reconciled) {
                    localStorage.removeItem(key);
                }
            } catch (error) {
                console.error('Reconciliation failed:', error);
                // Leave transaction in storage to retry later
            }
        }
    }
    async reconcileTransaction(transactionData) {
        try {
            if (!this.backendAvailable) {
                return false;
            }

            const response = await fetch(`${this.baseUrl}/payments/reconcile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'api-key-puntosdepago': localStorage.getItem('apiKeyPuntosdepago')
                },
                credentials: 'include',
                body: JSON.stringify(transactionData)
            });

            if (!response.ok) {
                throw new Error(`Reconciliation failed: ${response.statusText}`);
            }

            const result = await response.json();
            return result.success;
        } catch (error) {
            console.error('Transaction reconciliation failed:', error);
            return false;
        }
    }

    dispose() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
            this.checkInterval = null;
        }
    }
}