<template>
  <div id="recibo">
    <img src="../img/logo-swissntet-puntosdepago-negro.png" alt="SWISSNET" class="receipt-logo">
    <span style="font-size: 0.7rem;"> 
      CUIT: 30-71665558-6<br>
      ING. BRUTOS: 30-71665558-6<br>
      José Rivera 440<br>
      N3328EFO<br>
      Jardín América - Misiones<br>
      0810-555-7947 (lin. rot.)<br>
      info@swiss-net.com.ar<br>
      www.swiss-net.com.ar
  </span>
    <hr>
    <h3 style="font-size: 1.5rem;">Cierre de Caja</h3>
    <span style="font-size: 0.8rem;">No válido como factura</span>
    <br>
    
    <hr>
    
    Fecha de Cierre: <label>{{ formattedDate }}</label><br>
    Usuario: <label>{{ userId }}</label>
    <hr>

    <span style="font-size: 1rem;">
      <b>Operaciones cerradas:</b> {{ numOperations }}<br><br>
      <b>Monto total:</b> $ {{ formatAmount(totalAmount) }}<br>
      <b>Monto de comisión:</b> $ {{ formatAmount(commissionAmount) }}<br>
      <b>Monto a rendir:</b> $ {{ formatAmount(totalAmount - commissionAmount) }}
    </span>

    <hr style="margin-top: 30px;">
    <div class="signature-section">
      <div class="signature-line">____________________</div>
      <div class="signature-label">Firma Cajero</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    formattedDate: {
      type: String,
      required: true
    },
    numOperations: {
      type: Number,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    commissionAmount: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatAmount(amount) {
      return Number(amount).toFixed(2);
    }
  }
};
</script>

<style scoped>
#recibo {
  text-align: left;
  margin: 0 auto;
  font-family: 'monospace';
  font-size: 14px;
  word-wrap: break-word;
}

.receipt-logo {
  max-width: 240px;
  height: auto;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.signature-section {
  margin-top: 50px;
  text-align: center;
}

.signature-line {
  margin-bottom: 5px;
  font-size: 20px;
}

.signature-label {
  font-size: 14px;
}

@media print {
  @page {
    margin: 0;
    size: 80mm 297mm;
  }

  #recibo {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    padding: 2mm !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    background-color: white !important;
    font-size: 10pt !important;
    line-height: 1.2 !important;
    color: black !important;
  }

  .receipt-logo {
    max-width: 50mm !important;
    height: auto !important;
    margin: 0 auto 5mm !important;
    display: block !important;
  }

  .signature-section {
    margin-top: 30mm !important;
    page-break-inside: avoid !important;
  }
}
</style>