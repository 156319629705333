<template>
    <div class="reconciliation-notifications" v-if="notifications.length">
      <TransitionGroup name="notification">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="notification"
          :class="getNotificationClass(notification.type)"
        >
          <div class="notification-content">
            <span class="notification-message">{{ notification.message }}</span>
            <button 
              class="notification-close"
              @click="closeNotification(notification.id)"
            >
              ×
            </button>
          </div>
          <div 
            v-if="notification.type === 'info'"
            class="notification-progress"
          ></div>
        </div>
      </TransitionGroup>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useReconciliationStore } from '@/stores/reconciliationStore';
  
  export default {
    name: 'ReconciliationNotification',
    
    setup() {
      const store = useReconciliationStore();
  
      const notifications = computed(() => store.currentNotifications);
      const hasActiveProcess = computed(() => store.reconciliationInProgress);
  
      const getNotificationClass = (type) => ({
        'notification-success': type === 'success',
        'notification-error': type === 'error',
        'notification-info': type === 'info'
      });
  
      const closeNotification = (id) => {
        store.removeNotification(id);
      };
  
      return {
        notifications,
        hasActiveProcess,
        getNotificationClass,
        closeNotification
      };
    }
  };
  </script>
  
  <style scoped>
  .reconciliation-notifications {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .notification {
    min-width: 300px;
    padding: 15px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
  }
  
  .notification-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
  }
  
  .notification-message {
    flex: 1;
    text-align: left;
  }
  
  .notification-close {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0 5px;
    opacity: 0.6;
  }
  
  .notification-close:hover {
    opacity: 1;
  }
  
  .notification-success {
    background-color: #f0fdf4;
    border-left: 4px solid #22c55e;
    color: #166534;
  }
  
  .notification-error {
    background-color: #fef2f2;
    border-left: 4px solid #ef4444;
    color: #991b1b;
  }
  
  .notification-info {
    background-color: #eff6ff;
    border-left: 4px solid #3b82f6;
    color: #1e40af;
  }
  
  .notification-progress {
    height: 2px;
    background-color: currentColor;
    opacity: 0.2;
    margin-top: 10px;
  }
  
  /* Transition animations */
  .notification-enter-active,
  .notification-leave-active {
    transition: all 0.3s ease;
  }
  
  .notification-enter-from {
    opacity: 0;
    transform: translateX(30px);
  }
  
  .notification-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
  </style>