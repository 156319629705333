import { TestService } from './TestService';

export class CierreTestService extends TestService {
  constructor() {
    super();
  }

  async getUsers() {
    return this.makeRequest('/users');
  }

  async getDashboardData(userId) {
    return this.makeRequest(`/operaciones/dashboard?userId=${userId}`);
  }

  async closeOperations(userId) {
    return this.makeRequest('/operaciones/closeOperations', {
      method: 'POST',
      body: JSON.stringify({ userid: userId })
    });
  }

  async updateCommissionRate(userId, rate) {
    return this.makeRequest('/operaciones/commission', {
      method: 'PUT',
      body: JSON.stringify({
        userid: userId,
        commissionRate: rate
      })
    });
  }
}