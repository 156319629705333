import { PaymentService } from '@/services/PaymentService';

export const paymentScenarios = [
  {
    id: 'normal-payment',
    name: 'Flujo de pago normal',
    description: 'Prueba una transaccion completa con verificación de estado',
    steps: [
      'Create pending transaction',
      'Process ISPcube payment',
      'Complete transaction',
      'Verify transaction status',
      'Verify reconciliation status'
    ],
    async run(result, testService) {
      const paymentService = new PaymentService();
      
      try {
        result.currentStep = 0;
        const paymentData = {
          userid: 'caja2',
          idcustomer: '104181',
          importe: 1.00
        };

        const paymentResult = await paymentService.processPayment(paymentData);
        if (!paymentResult.success) {
          throw new Error(`Payment failed: ${paymentResult.error}`);
        }

        // Verify the transaction status using testService
        result.currentStep = 3;
        const statusResult = await testService.makeRequest(
          `/payments/${paymentResult.transactionId}`
        );
        
        // Verify all statuses are correct
        const expectedStatuses = {
          status: 'completed',
          reconciliation_status: 'not_needed'
        };

        const statusChecks = {
          transaction_status: statusResult.transaction.status === expectedStatuses.status,
          reconciliation_status: statusResult.transaction.reconciliation_status === expectedStatuses.reconciliation_status,
          has_codigo_cobranza: Boolean(statusResult.transaction.codigoCobranza),
          has_completed_at: Boolean(statusResult.transaction.completed_at)
        };

        // Verify operation was created using testService
        const operationResult = await testService.makeRequest(
          `/operaciones?codigoCobranza=${statusResult.transaction.codigoCobranza}`
        );
        
        statusChecks.operation_created = operationResult.operations?.length > 0;

        // Check if all verifications passed
        const allChecksPass = Object.values(statusChecks).every(check => check === true);

        if (allChecksPass) {
          result.status = 'success';
          result.data = {
            payment: paymentResult,
            statusChecks,
            transaction: statusResult.transaction,
            operation: operationResult.operations?.[0]
          };
        } else {
          throw new Error(`Status verification failed: ${JSON.stringify(statusChecks, null, 2)}`);
        }

      } catch (error) {
        result.status = 'failed';
        result.error = error.message;
        console.error('Test error:', error);
      }
    }
  },

  {
        id: 'backend-failure',
        name: 'Error de Backend con ISPcube correcto',
        description: 'Simula error de backend y reconciliacion de operacion',
        steps: [
            'Create pending transaction',
            'Process ISPcube payment',
            'Simulate backend failure',
            'Verify transaction status',
            'Verify reconciliation status'
        ],
        async run(result, testService) {
            const paymentService = new PaymentService();
            
            try {
                // Enable backend failure simulation
                paymentService.setTestMode({ simulateBackendFailure: true });
                result.currentStep = 0;

                const paymentData = {
                    userid: 'caja2',
                    idcustomer: '104181',
                    importe: 1.00
                };

                // Process payment
                const paymentResult = await paymentService.processPayment(paymentData);
                console.log('Payment Result:', JSON.stringify(paymentResult, null, 2));

                // Verify stored reconciliation data
                const storedItems = Object.keys(localStorage)
                    .filter(key => key.startsWith('pending-payment-'))
                    .map(key => JSON.parse(localStorage.getItem(key)));

                if (storedItems.length === 0) {
                    throw new Error('Expected payment data to be stored for reconciliation');
                }

                const storedPayment = storedItems[0];

                // Verify the payment is properly stored for reconciliation
                const verificationChecks = {
                    hasTransactionId: Boolean(storedPayment.transactionId),
                    hasCodigoCobranza: Boolean(storedPayment.codigoCobranza),
                    hasTimestamp: Boolean(storedPayment.timestamp),
                    hasPaymentData: Boolean(storedPayment.paymentData),
                    matchesOriginalData: 
                        storedPayment.paymentData.userid === paymentData.userid &&
                        storedPayment.paymentData.idcustomer === paymentData.idcustomer &&
                        storedPayment.paymentData.importe === paymentData.importe
                };

                const allChecksPass = Object.values(verificationChecks)
                    .every(check => check === true);

                if (!allChecksPass) {
                    throw new Error(
                        'Stored reconciliation data verification failed:\n' +
                        JSON.stringify(verificationChecks, null, 2)
                    );
                }

                // Verify no operation was created yet
                const operationResult = await testService.makeRequest(
                    `/operaciones?codigoCobranza=${paymentResult.codigoCobranza}`
                );

                if (operationResult.operations?.length > 0) {
                    throw new Error('Expected no operation to be created during backend failure');
                }

                result.status = 'success';
                result.data = {
                    payment: paymentResult,
                    verificationChecks,
                    storedReconciliation: storedPayment
                };

            } catch (error) {
                result.status = 'failed';
                result.error = error.message;
                console.error('Test error:', error);
            } finally {
                if (paymentService.resetTestMode) {
                    paymentService.resetTestMode();
                }
                // Clean up any stored reconciliation data
                Object.keys(localStorage)
                    .filter(key => key.startsWith('pending-payment-'))
                    .forEach(key => localStorage.removeItem(key));
            }
        }
    },

  {
    id: 'reconciliation',
    name: 'Reconciliación de pago',
    description: 'Prueba la reconciliacion completa despues de un error de backend',
    steps: [
      'Cleanup existing transactions',
      'Create failed payment',
      'Verify initial status',
      'Trigger reconciliation',
      'Verify final status',
      'Check operation creation'
    ],
    async run(result, testService) {
      const paymentService = new PaymentService();
      
      try {
        // Initial cleanup
        result.currentStep = 0;
        console.log('Starting cleanup...');
        
        // Clear localStorage of any pending transactions
        Object.keys(localStorage)
          .filter(key => key.startsWith('pending-payment-') || key.includes('reconciliation'))
          .forEach(key => {
            console.log('Removing stored item:', key);
            localStorage.removeItem(key);
          });

        // Reset payment service state
        if (paymentService.resetTestMode) {
          paymentService.resetTestMode();
        }
        
        if (paymentService.availabilityService?.reconcilePendingTransactions) {
          await paymentService.availabilityService.reconcilePendingTransactions();
        }
        
        // Log clean state
        console.log('Local storage after cleanup:', 
          Object.keys(localStorage)
            .filter(key => key.startsWith('pending-payment-') || key.includes('reconciliation'))
        );

        // Wait for cleanup to complete
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Start the test with simulated failure
        paymentService.setTestMode({ simulateBackendFailure: true });
        
        const paymentData = {
          userid: 'caja2',
          idcustomer: '104181',
          importe: 1.00
        };

        // Create failed payment
        result.currentStep = 1;
        const failedPayment = await paymentService.processPayment(paymentData);
        console.log('Failed Payment Result:', failedPayment);

        // Verify initial state
        result.currentStep = 2;
        const initialStatusData = await testService.makeRequest(
          `/payments/${failedPayment.transactionId}`
        );
        
        console.log('Initial Status:', initialStatusData);

        // Reset test mode for reconciliation
        if (paymentService.resetTestMode) {
          paymentService.resetTestMode();
        }
        
        // Trigger reconciliation
        result.currentStep = 3;
        const reconcileResult = await testService.makeRequest(
          '/payments/reconcile',
          {
            method: 'POST',
            body: JSON.stringify({
              userid: paymentData.userid,
              idcustomer: paymentData.idcustomer,
              importe: paymentData.importe,
              codigoCobranza: failedPayment.codigoCobranza
            })
          }
        );

        console.log('Reconciliation Result:', reconcileResult);

        // Wait for reconciliation to complete
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Verify final status
        result.currentStep = 4;
        const finalStatusData = await testService.makeRequest(
          `/payments/${failedPayment.transactionId}`
        );
        
        console.log('Final Status:', finalStatusData);

        // Check operation creation
        result.currentStep = 5;
        const operationData = await testService.makeRequest(
          `/operaciones?codigoCobranza=${failedPayment.codigoCobranza}`
        );
        
        console.log('Operation Data:', operationData);

        const success = 
          finalStatusData.transaction?.status === 'completed' &&
          finalStatusData.transaction?.reconciliation_status === 'completed' &&
          operationData.operations?.length > 0;

        if (success) {
          result.status = 'success';
          result.data = {
            initialStatus: initialStatusData,
            reconciliation: reconcileResult,
            finalStatus: finalStatusData,
            operation: operationData.operations[0]
          };
        } else {
          throw new Error('Reconciliation verification failed');
        }

      } catch (error) {
        result.status = 'failed';
        result.error = error.message;
        console.error('Test error:', error);
      } finally {
        // Final cleanup
        if (paymentService.resetTestMode) {
          paymentService.resetTestMode();
        }
        Object.keys(localStorage)
          .filter(key => key.startsWith('pending-payment-') || key.includes('reconciliation'))
          .forEach(key => localStorage.removeItem(key));
      }
    }
  }
];