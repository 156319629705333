<template>
  <div class="d-flex flex-column h-100 text-center">
    <!-- Error Alert -->
    <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show m-2" role="alert">
      {{ errorMessage }}
      <button 
        class="btn btn-primary mt-2"
        @click="redirectToSeleccionaCliente">
        Volver a Intentar
      </button>
      <button type="button" class="btn-close" @click="clearError" aria-label="Close"></button>
    </div>

    <!-- Reconciliation Alert -->
    <div v-if="isProvisionalReceipt" class="alert alert-warning alert-dismissible fade show m-2" role="alert">
      <strong>Comprobante Provisional</strong>
      <p>Este pago está siendo procesado y será confirmado automáticamente.</p>
    </div>

    <!-- Processing Overlay -->
    <div v-if="isProcessing" class="processing-overlay">
      <div class="spinner-wrapper">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Procesando...</span>
        </div>
        <p class="mt-2">Procesando pago...</p>
      </div>
    </div>

    <main class="container" v-if="paymentSuccessful || isProcessing">
      <div class="starter-template text-center px-3">
        <div class="row">
          <!-- Receipt Column -->
          <div class="col-md-8 receipt-container">
            <div class="card text-center">
              <div class="card-body">
                <div id="recibo" class="receipt-content">
                  <!-- Provisional Receipt Warning -->
                  <div v-if="isProvisionalReceipt" class="provisional-warning">
                    *** COMPROBANTE PROVISIONAL ***
                  </div>

                  <img src="../img/logo-swissntet-puntosdepago-negro.png" alt="SWISSNET" class="receipt-logo">
                  <div class="company-info">
                    José Rivera 440<br>
                    N3328EFO<br>
                    Jardín América - Misiones<br>
                    0810-555-7947<br>
                    info@swiss-net.com.ar<br>
                    www.swiss-net.com.ar
                  </div>
                  <hr class="receipt-divider">
                  
                  <div class="receipt-header">
                    <h3>{{ isProvisionalReceipt ? 'Comprobante Provisional' : 'Recibo X' }}</h3>
                    <span class="receipt-subheader">No válido como factura</span>
                    <div class="fiscal-info">
                      CUIT: 30-71665558-6<br>
                      ING. BRUTOS: 30-71665558-6
                    </div>
                  </div>
                  <hr class="receipt-divider">

                  <div class="transaction-info">
                    <div class="info-row">
                      <span class="label">Fecha:</span>
                      <span class="value">{{ formattedDate }}</span>
                    </div>
                    <div class="info-row">
                      <span class="label">Código:</span>
                      <span class="value">{{ codigoCobranzaDevuelto }}</span>
                    </div>
                    <div v-if="receiptNumber" class="info-row">
                      <span class="label">Nº de Comprobante:</span>
                      <span class="value">{{ receiptNumber }}</span>
                    </div>
                  </div>
                  <hr class="receipt-divider">

                  <div class="customer-info">
                    <div class="info-row"><b>Cliente:</b> {{ nombreCliente }}</div>
                    <div class="info-row">{{ condicionIVACliente }}</div>
                    <div class="info-row"><b>Dirección:</b> {{ direccionCliente }}</div>
                    <div class="info-row"><b>Ciudad:</b> {{ ciudadCliente }}</div>
                  </div>
                  <hr class="receipt-divider">

                  <div class="payment-info">
                    <div class="info-row">
                      <b>Recibí la suma de: $</b> {{ formatAmount(totalDepositado) }}
                    </div>
                    <div class="info-row">En concepto de Pago</div>
                    <div class="info-row">
                      <b>Saldo en Cuenta: $</b> {{ formatAmount(saldoNuevo) }}
                    </div>
                  </div>

                  <!-- Provisional Receipt Footer -->
                  <div v-if="isProvisionalReceipt" class="provisional-footer">
                    <hr class="receipt-divider">
                    <p class="text-center">
                      Este es un comprobante provisional.<br>
                      Conserve este documento.<br>
                      La operación será confirmada automáticamente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Actions Column -->
          <div class="col-md-4 d-flex flex-column justify-content-center">
            <div v-if="paymentSuccessful">
              <button 
                @click="printReceipt" 
                class="btn btn-primary mb-3"
                :disabled="isPrinting || isProcessing">
                {{ isPrinting ? 'Imprimiendo...' : 'Imprimir Comprobante' }}
              </button>
              <button 
                @click="finishTransaction" 
                class="btn btn-secondary"
                :disabled="isPrinting || isProcessing">
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { PaymentService } from '@/services/PaymentService';
import { PaymentReconciliation } from '@/services/paymentReconciliation';

export default {
  data() {
    return {
      saldoInicial: parseFloat(localStorage.getItem('clienteDeuda')) || 0,
      totalDepositado: parseFloat(localStorage.getItem('totalDepositado')) || 0,
      saldoNuevo: 0,
      formattedDate: new Date().toLocaleString('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }),
      nombreCliente: localStorage.getItem('clienteNombre') || '',
      direccionCliente: localStorage.getItem('clienteDireccion') || '',
      condicionIVACliente: localStorage.getItem('clienteCondicionIVA') || '',
      ciudadCliente: localStorage.getItem('clienteCiudad') || '',
      codigoCobranzaDevuelto: '',
      receiptNumber: '',
      isProcessing: false,
      isPrinting: false,
      errorMessage: '',
      paymentSuccessful: false,
      isProvisionalReceipt: false,
      redirectTimer: null,
      reconciliationStatus: null
    };
  },

  methods: {
    formatAmount(amount) {
      if (typeof amount !== 'number') {
        amount = parseFloat(amount) || 0;
      }
      return amount.toFixed(2);
    },
    async processPayment() {
      if (this.isProcessing) return;

      try {
        this.isProcessing = true;
        this.errorMessage = '';

        const paymentService = new PaymentService();
        const paymentData = {
          userid: localStorage.getItem('userid'),
          idcustomer: localStorage.getItem('clienteIdCustomer'),
          importe: this.totalDepositado
        };

        const result = await paymentService.processPayment(paymentData);

        if (result.success) {
          this.codigoCobranzaDevuelto = result.codigoCobranza;
          this.receiptNumber = result.receiptNumber;
          this.isProvisionalReceipt = result.requiresReconciliation;
          this.calculateNewBalance();
          this.paymentSuccessful = true;

          if (result.requiresReconciliation) {
            // Store transaction data for reconciliation
            localStorage.setItem('pendingReconciliation', JSON.stringify({
              transactionId: result.transactionId,
              codigoCobranza: result.codigoCobranza,
              timestamp: new Date().toISOString()
            }));
          }

          // Auto-print after successful payment
          await this.$nextTick();
          this.printReceipt();
        } else {
          throw new Error(result.error || 'Error procesando el pago');
        }
      } catch (error) {
        // If we have ISPcube success but backend failed
        if (error.ispcubeResponse && error.ispcubeResponse.success) {
          this.handlePartialSuccess(error.ispcubeResponse);
        } else {
          this.errorMessage = 'Error de conexión. Por favor, verifique su conexión a internet.';
          this.paymentSuccessful = false;
          this.codigoCobranzaDevuelto = '';
          this.saldoNuevo = 0;
        }
      } finally {
        this.isProcessing = false;
      }
    },

    async handlePartialSuccess(ispcubeResponse) {
      // Show provisional receipt if ISPcube succeeded but backend failed
      this.codigoCobranzaDevuelto = ispcubeResponse.codigoCobranza;
      this.isProvisionalReceipt = true;
      this.paymentSuccessful = true;
      this.calculateNewBalance();

      // Store reconciliation data
      const reconciliationData = {
        codigoCobranza: ispcubeResponse.codigoCobranza,
        timestamp: new Date().toISOString(),
        paymentData: {
          userid: localStorage.getItem('userid'),
          idcustomer: localStorage.getItem('clienteIdCustomer'),
          importe: this.totalDepositado
        }
      };

      localStorage.setItem('pendingReconciliation', JSON.stringify(reconciliationData));
    },
    // In PagoRealizado.vue methods
    async handlePaymentResult(result) {
        if (result.requiresReconciliation) {
            this.showReconciliationStatus = true;
            this.reconciliationMessage = 'Verificando estado del pago...';
            
            const reconciliation = new PaymentReconciliation();
            const reconcileResult = await reconciliation.reconcilePayment(
                reconciliation.getPendingReconciliation()
            );

            if (reconcileResult.success) {
                this.paymentSuccessful = true;
                this.reconciliationMessage = 'Pago verificado exitosamente';
            } else {
                this.errorMessage = 'Error en la verificación del pago. Por favor, contacte a soporte.';
            }
        }
    },

    async printReceipt() {
      if (this.isPrinting) return;

      try {
        this.isPrinting = true;
        await new Promise(resolve => setTimeout(resolve, 500)); // Allow UI to update
        window.print();
        
        // Set timer for redirect after print
        this.redirectTimer = setTimeout(() => {
          this.redirectToSeleccionaCliente();
        }, 1000);
      } catch (error) {
        this.errorMessage = 'Error al imprimir el recibo';
      } finally {
        this.isPrinting = false;
      }
    },

    clearError() {
      this.errorMessage = '';
    },

    calculateNewBalance() {
      this.saldoNuevo = Number((this.saldoInicial + this.totalDepositado).toFixed(2));
    },

    redirectToSeleccionaCliente() {
      if (this.redirectTimer) {
        clearTimeout(this.redirectTimer);
      }
      this.$router.push('/seleccionaCliente');
    },

    finishTransaction() {
      this.redirectToSeleccionaCliente();
    }
  },

  async mounted() {
    if (!this.totalDepositado) {
      this.errorMessage = 'No se encontró monto de pago';
      setTimeout(() => this.redirectToSeleccionaCliente(), 3000);
      return;
    }

    await this.processPayment();
  },

  beforeUnmount() {
    if (this.redirectTimer) {
      clearTimeout(this.redirectTimer);
    }
  }
};
</script>

<style>
/* Screen styles */
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.spinner-wrapper {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.receipt-content {
  text-align: left;
  margin: 0 auto;
  font-family: monospace;
  font-size: 14px;
  padding: 10px;
}

.receipt-logo {
  max-width: 240px;
  height: auto;
  display: block;
  margin: 0 auto 10px;
}

.receipt-divider {
  border-top: 1px solid #000;
  margin: 10px 0;
}

.info-row {
  margin: 5px 0;
}

.provisional-warning {
  color: red;
  border: 2px solid red;
  padding: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.provisional-footer {
  margin-top: 20px;
  font-size: 0.9em;
  color: #666;
}

/* Print styles */
@media print {
  @page {
    margin: 0;
    padding: 0;
    size: 80mm auto; /* Allow height to adjust automatically */
  }

  html, body {
    margin: 0 !important;
    padding: 0 !important;
    width: 80mm !important;
  }

  /* Hide all other elements */
  body * {
    visibility: hidden;
  }

  /* Show only receipt content */
  #recibo, #recibo * {
    visibility: visible;
  }

  #recibo {
    position: fixed;
    left: 0;
    top: 0;
    width: 80mm;
    margin: 0;
    padding: 2mm;
    background: white;
  }
  .provisional-warning {
    color: red !important;
    border-color: red !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  /* Reset container margins */
  .container, 
  .starter-template,
  .card,
  .card-body {
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Adjust logo size and margins */
  .receipt-logo {
    max-width: 50mm;
    margin: 0 auto 2mm;
  }

  /* Hide non-receipt elements */
  .btn,
  .alert,
  .processing-overlay,
  .card-title {
    display: none !important;
  }

  /* Ensure proper font size and spacing */
  .receipt-content {
    font-size: 10pt;
    line-height: 1.2;
  }

  /* Adjust spacing between sections */
  .receipt-divider {
    margin: 1mm 0;
  }

  .info-row {
    margin: 1mm 0;
  }
}
</style>