export const cierreScenarios = [
    {
      id: 'normal-closing',
      name: 'Cierre de caja normal',
      description: 'Prueba una transaccion de cierre completo y comisión',
      steps: [
        'Select user to test',
        'Fetch open operations',
        'Calculate totals and commission',
        'Close operations',
        'Generate receipt',
        'Verify operation status'
      ],
      async run(result, testService) {
        try {
          // 1. Get testing user
          result.currentStep = 0;
      //    const currentUserId = localStorage.getItem('userid');
          const isAdmin = localStorage.getItem('userRole') === 'admin';
          let testUserId = 'caja2';
          
          if (isAdmin) {
            const { users } = await testService.getUsers();
            const posUser = users.find(u => u.userRole === 'POS');
            if (posUser) {
              testUserId = posUser.userid;
            }
          }
  
          if (!testUserId) {
            throw new Error('No valid user found for testing');
          }
  
          // 2. Fetch dashboard data with explicit userid parameter
          result.currentStep = 1;
          const dashboardData = await testService.makeRequest(
            `/operaciones/dashboard?userId=${testUserId}`
          );

          if (!dashboardData) {
            throw new Error('Failed to fetch dashboard data');
          }

          console.log('Dashboard Data:', dashboardData);
  
          // Save expected values with defaults
          const expectedTotals = {
            numOperations: dashboardData.numOperations || 0,
            totalAmount: dashboardData.totalAmount || 0,
            commissionAmount: dashboardData.commissionAmount || 0
          };
  
          // If no operations, test passes
          if (expectedTotals.numOperations === 0) {
            result.status = 'success';
            result.data = {
              message: 'No operations to close',
              testUser: testUserId,
              userType: isAdmin ? 'admin using POS user' : 'current user'
            };
            return;
          }

          result.currentStep = 2;
  
          // 3. Close operations with explicit userid
          const closeResult = await testService.makeRequest('/operaciones/closeOperations', {
            method: 'POST',
            body: JSON.stringify({ userid: testUserId })
          });

          if (!closeResult) {
            throw new Error('Failed to close operations');
          }

          console.log('Close Result:', closeResult);
          result.currentStep = 3;
  
          // 4. Compare results with tolerance for floating point
          const mismatchedFields = [];
          Object.keys(expectedTotals).forEach(key => {
            const expected = Number(expectedTotals[key]);
            const actual = Number(closeResult[key]);
            const tolerance = 0.01;

            if (Math.abs(expected - actual) > tolerance) {
              mismatchedFields.push(
                `${key}: expected ${expected}, got ${actual}`
              );
            }
          });
  
          if (mismatchedFields.length > 0) {
            console.error('Expected:', expectedTotals);
            console.error('Actual:', closeResult);
            throw new Error(`Closing totals mismatch: ${mismatchedFields.join(', ')}`);
          }
  
          result.currentStep = 4;
  
          result.status = 'success';
          result.data = {
            testUser: testUserId,
            userType: isAdmin ? 'admin using POS user' : 'current user',
            expected: expectedTotals,
            actual: closeResult,
            timings: {
              started: result.startTime,
              completed: Date.now()
            }
          };
  
        } catch (error) {
          result.status = 'failed';
          result.error = error.message;
          console.error('Test error:', error);
          console.error('Test data:', result.data);
        }
      }
    },
     
    {
        id: 'commission-calculation',
        name: 'Cálculo de comision',
        description: 'prueba el cálculo de comisión con diferentes valores (no funciona)',
        steps: [
          'Select user to test',
          'Fetch user commission rate',
          'Calculate commission for operations',
          'Update commission rate',
          'Verify new calculations',
          'Restore original rate'
        ],
        async run(result, testService) {
          try {
            const isAdmin = localStorage.getItem('userRole') === 'admin';
            
            if (!isAdmin) {
              throw new Error('Commission rate tests require admin privileges');
            }
      
            result.currentStep = 0;
      
            // Get list of users and filter for test user
            const { users } = await testService.getUsers();
            console.log('Available users:', users); // Debug log
            
            // Look for a non-admin user with a commission rate
            const testUser = users.find(u => 
              u.userRole === 'POS' && // Matches exactly as in database
              u.userid !== '1' && // Not admin
              u.userid !== 'totem1' // Not totem
            );
            
            if (!testUser) {
              throw new Error('No suitable user found for testing (needs POS role)');
            }
      
            console.log('Selected test user:', testUser); // Debug log
      
            const testRate = 25.00;
            result.currentStep = 1;
      
            // Get current data
            const initialData = await testService.getDashboardData(testUser.userid);
            console.log('Initial data:', initialData); // Debug log
      
            // Update commission rate
            await testService.updateCommissionRate(testUser.userid, testRate);
            result.currentStep = 2;
      
            // Allow a small delay for the update to take effect
            await new Promise(resolve => setTimeout(resolve, 1000));
      
            // Get updated data
            const updatedData = await testService.getDashboardData(testUser.userid);
            console.log('Updated data:', updatedData); // Debug log
            result.currentStep = 3;
      
            // Verify calculations with some tolerance for floating point
            const expectedCommission = updatedData.numOperations * testRate;
            const commissionMatches = Math.abs(updatedData.commissionAmount - expectedCommission) < 0.01;
      
            if (!commissionMatches) {
              throw new Error(
                `Commission calculation incorrect:\n` +
                `Expected: ${expectedCommission}\n` +
                `Got: ${updatedData.commissionAmount}\n` +
                `Operations: ${updatedData.numOperations}\n` +
                `Rate: ${testRate}`
              );
            }
      
            // Restore original rate
            await testService.updateCommissionRate(testUser.userid, initialData.commissionRate);
            result.currentStep = 4;
      
            // Final verification
            const finalData = await testService.getDashboardData(testUser.userid);
            const originalRateRestored = Math.abs(finalData.commissionRate - initialData.commissionRate) < 0.01;
      
            if (!originalRateRestored) {
              throw new Error('Failed to restore original commission rate');
            }
      
            result.status = 'success';
            result.data = {
              testUser: testUser.userid,
              originalRate: initialData.commissionRate,
              testRate,
              numOperations: updatedData.numOperations,
              calculatedCommission: updatedData.commissionAmount,
              expectedCommission,
              finalRate: finalData.commissionRate
            };
      
          } catch (error) {
            result.status = 'failed';
            result.error = error.message;
            console.error('Commission test error:', error);
          }
        }
      }
  ];