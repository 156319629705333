import { TestService } from './TestService';

export class PaymentTestService extends TestService {
  constructor() {
    super();
  }

  // Payment specific methods
  async getTransactionStatus(transactionId) {
    return this.makeRequest(`/payments/${transactionId}`);
  }

  async reconcileTransaction(data) {
    return this.makeRequest('/payments/reconcile', {
      method: 'POST',
      body: JSON.stringify(data)
    });
  }
}