// src/tests/services/TestService.js
export class TestService {
    constructor() {
      this.baseUrl = process.env.VUE_APP_API_URL;
    }
  
    async getHeaders() {
        // Base headers that are always included
        const headers = {
          'Content-Type': 'application/json',
          'api-key-puntosdepago': localStorage.getItem('apiKeyPuntosdepago')
        };
    
        // Add user role if available
        const userRole = localStorage.getItem('userRole');
        if (userRole) {
          headers['user-role'] = userRole;
        }
    
        return headers;
      }
    
      async makeRequest(endpoint, options = {}) {
        try {
          const headers = await this.getHeaders();
          
          const response = await fetch(`${this.baseUrl}${endpoint}`, {
            ...options,
            headers: {
              ...headers,
              ...options.headers
            },
            credentials: 'include'
          });
    
          if (!response.ok) {
            const error = new Error(`HTTP error! status: ${response.status}`);
            error.status = response.status;
            throw error;
          }
    
          return response.json();
        } catch (error) {
          console.error(`API request failed for ${endpoint}:`, error);
          throw error;
        }
      }
    async runTest(scenario, result) {
      try {
        await scenario.run(result, this);
      } catch (error) {
        result.status = 'failed';
        result.error = error.message;
      }
    }
  
    async runAllTests(scenarios) {
      const results = [];
      for (const scenario of scenarios) {
        const result = {
          id: scenario.id,
          name: scenario.name,
          status: 'running',
          currentStep: 0,
          startTime: Date.now()
        };
        await this.runTest(scenario, result);
        results.push(result);
      }
      return results;
    }
  }

  
  export class CierreTestService extends TestService {
    constructor() {
      super();
    }
  
    // Cash closing specific methods
    async getDashboardData(userId) {
      return this.makeRequest(`/operaciones/dashboard?userId=${userId}`);
    }
  
    async closeOperations(userId) {
      return this.makeRequest('/operaciones/closeOperations', {
        method: 'POST',
        body: JSON.stringify({ userid: userId })
      });
    }
  
    async updateCommissionRate(userId, rate) {
      return this.makeRequest('/operaciones/commission', {
        method: 'PUT',
        body: JSON.stringify({
          userid: userId,
          commissionRate: rate
        })
      });
    }
  }
  

  export class PaymentTestService extends TestService {
    constructor() {
      super();
    }
  
    // Payment specific methods
    async getTransactionStatus(transactionId) {
      return this.makeRequest(`/payments/${transactionId}`);
    }
  
    async reconcileTransaction(data) {
      return this.makeRequest('/payments/reconcile', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    }
  }