<template>
  <main class="container">
    <div class="starter-template text-center py-1 px-3">
      <div class="logo-wrapper">
        <div class="image-container">
          <img src="../img/logo-swissntet-pdp.png" alt="Logo">
        </div>
      </div>
      <div class="card text-center">
        <div class="card-body">
          <div class="login-container">
            <h1 class="card-title">Puntos de Pago Login</h1>
            <form @submit.prevent="loginUser">
              <div style="margin: 0.5rem;">
                <label for="username">Usuario:</label>
                <input 
                  type="text" 
                  v-model="username" 
                  id="username" 
                  required 
                  :disabled="isLoading"
                />
              </div>
              <div style="margin: 0.5rem;">
                <label for="password">Contraseña:</label>
                <input 
                  type="password" 
                  v-model="password" 
                  id="password" 
                  required 
                  :disabled="isLoading"
                />
              </div>
              <button 
                type="submit" 
                class="btn" 
                :disabled="isLoading"
              >
                {{ isLoading ? 'Iniciando sesión...' : 'Login' }}
              </button>
            </form>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
            
            <!-- Version info in development -->
            <div v-if="isDevelopment" class="version-info mt-3">
              <small class="text-muted">Version: {{ currentVersion }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import { userRole, isLoggedIn } from '@/stores/userState';
import { VersionService } from '@/services/VersionService';
import { ref, computed } from 'vue';

export default {
  setup() {
    const currentVersion = ref(process.env.VUE_APP_VERSION || '1.0.0');
    const isDevelopment = computed(() => process.env.NODE_ENV === 'development');

    return { 
      currentVersion,
      isDevelopment
    };
  },

  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      isLoading: false
    };
  },

  methods: {
    async loginUser() {
      if (this.isLoading) return;
      
      this.isLoading = true;
      this.errorMessage = '';

      try {
        // Check version before login attempt
        if (!(await VersionService.checkVersion())) {
          throw new Error('Actualización requerida. La página se recargará automáticamente.');
        }

        const response = await fetch(`${process.env.VUE_APP_API_URL}/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nombre: this.username,
            password: this.password
          }),
          credentials: 'include'
        });

        const data = await response.json();
        console.log('Login response:', data);

        if (data.success) {
          // Clear any existing data first
          localStorage.clear();
          sessionStorage.clear();

          // Save the new session data
          localStorage.setItem('apiKeyIspcube', data.apiKeyIspcube);
          localStorage.setItem('apiTokenIspcube', data.apiTokenIspcube);
          localStorage.setItem('apiKeyPuntosdepago', data.apiKeyPuntosdepago);
          localStorage.setItem('userid', data.userid);
          localStorage.setItem('userRole', data.userRole);
          localStorage.setItem('userName', this.username);

          // Update global state
          userRole.value = data.userRole;
          isLoggedIn.value = true;

          // Redirect to the home page
          this.$router.push('/');
        } else {
          this.errorMessage = data.message;
        }
      } catch (error) {
        console.error('Login error:', error);
        this.errorMessage = error.message || 'Ocurrió un error durante el inicio de sesión. Por favor, intente nuevamente.';
        
        // Clear sensitive data on error
        this.password = '';
        
        // Clear storage if login failed
        if (!isLoggedIn.value) {
          localStorage.clear();
          sessionStorage.clear();
        }
      } finally {
        this.isLoading = false;
      }
    }
  },

  async mounted() {
    // Clear any existing session data on mount
    if (!isLoggedIn.value) {
      localStorage.clear();
      sessionStorage.clear();
    }

    // Initial version check
    try {
      await VersionService.checkVersion();
    } catch (error) {
      console.error('Initial version check failed:', error);
    }
  }
};
</script>
<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

.logo-wrapper {
  max-width: 340px;
  margin: 0 auto;
}

.version-info {
  opacity: 0.7;
  font-size: 0.8rem;
}

/* Disable button styles */
.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Loading state styles */
.btn:disabled {
  background-color: #6c757d;
}

.text-danger {
  margin-top: 1rem;
}
</style>