// paymentReconciliation.js

class PaymentReconciliation {
    static STORAGE_KEY = 'pendingReconciliation';
    static RECONCILIATION_STATUS = {
        PENDING: 'PENDING',
        IN_PROGRESS: 'IN_PROGRESS',
        COMPLETED: 'COMPLETED',
        FAILED: 'FAILED'
    };

    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
    }

    // Store pending reconciliation data
    storePendingReconciliation(paymentData, ispcubeResponse) {
        const reconciliationData = {
            timestamp: new Date().toISOString(),
            status: PaymentReconciliation.RECONCILIATION_STATUS.PENDING,
            paymentData: {
                userid: paymentData.userid,
                idcustomer: paymentData.idcustomer,
                importe: paymentData.importe,
                codigoCobranza: ispcubeResponse.codigoCobranza,
                attempts: 0
            }
        };

        localStorage.setItem(PaymentReconciliation.STORAGE_KEY, JSON.stringify(reconciliationData));
        return reconciliationData;
    }

    // Check for pending reconciliations
    getPendingReconciliation() {
        const stored = localStorage.getItem(PaymentReconciliation.STORAGE_KEY);
        return stored ? JSON.parse(stored) : null;
    }

    // Attempt to reconcile a pending payment
    async reconcilePayment(reconciliationData) {
        try {
            reconciliationData.status = PaymentReconciliation.RECONCILIATION_STATUS.IN_PROGRESS;
            localStorage.setItem(PaymentReconciliation.STORAGE_KEY, JSON.stringify(reconciliationData));

            const response = await fetch(`${this.baseUrl}/payments/reconcile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                },
                credentials: 'include',
                body: JSON.stringify(reconciliationData.paymentData)
            });

            if (!response.ok) {
                throw new Error(`Reconciliation failed: ${response.statusText}`);
            }

            const result = await response.json();
            
            if (result.success) {
                this.clearReconciliation();
                return { success: true, message: 'Payment reconciled successfully' };
            } else {
                throw new Error(result.error || 'Reconciliation failed');
            }

        } catch (error) {
            reconciliationData.paymentData.attempts++;
            reconciliationData.status = PaymentReconciliation.RECONCILIATION_STATUS.FAILED;
            localStorage.setItem(PaymentReconciliation.STORAGE_KEY, JSON.stringify(reconciliationData));

            return {
                success: false,
                error: error.message,
                canRetry: reconciliationData.paymentData.attempts < 3
            };
        }
    }

    // Clear reconciliation data
    clearReconciliation() {
        localStorage.removeItem(PaymentReconciliation.STORAGE_KEY);
    }

    // Validate stored reconciliation data
    validateReconciliationData(data) {
        const requiredFields = ['userid', 'idcustomer', 'importe', 'codigoCobranza'];
        return requiredFields.every(field => data?.paymentData?.[field] !== undefined);
    }

    // Handle automatic reconciliation on app startup
    async handleAutoReconciliation() {
        const pendingReconciliation = this.getPendingReconciliation();
        
        if (pendingReconciliation && this.validateReconciliationData(pendingReconciliation)) {
            // Only attempt reconciliation for recent transactions (within last 24 hours)
            const timestamp = new Date(pendingReconciliation.timestamp);
            const now = new Date();
            const hoursDiff = (now - timestamp) / (1000 * 60 * 60);

            if (hoursDiff <= 24) {
                return await this.reconcilePayment(pendingReconciliation);
            } else {
                this.clearReconciliation();
                return {
                    success: false,
                    error: 'Reconciliation expired',
                    expired: true
                };
            }
        }
        return null;
    }
}

export { PaymentReconciliation };