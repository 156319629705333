// main.js
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/css/estilosCompartidos.css';

// Utilities
import "@/utils/funcionesCompartidas.js";

// Initialize Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Create and configure app
const app = createApp(App);

// Register plugins
app.use(router);
app.use(pinia);

// Error handling for development
if (process.env.NODE_ENV === 'development') {
    app.config.errorHandler = (err, vm, info) => {
        console.error('Global error:', err);
        console.error('Component:', vm);
        console.error('Error info:', info);
    };
}

// Mount app
app.mount('#app');

// Log initialization in development
if (process.env.NODE_ENV === 'development') {
    console.log('App initialized with:', {
        environment: process.env.NODE_ENV,
        apiUrl: process.env.VUE_APP_API_URL,
        version: process.env.VUE_APP_VERSION
    });
}