<template>
  <div id="app">
    <NavBar v-if="isLoggedIn" @logout="handleLogout" />
    <ReconciliationNotification />
    <router-view />
    
    <!-- Version info in development -->
    <div v-if="showDevInfo" class="version-info">
      <div>Version: {{ currentVersion }}</div>
      <div>API: {{ apiUrl }}</div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import { isLoggedIn } from '@/stores/userState';
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { VersionService } from '@/services/VersionService';
import { useRouter } from 'vue-router';
import { useReconciliationStore } from '@/stores/reconciliationStore';
import { PaymentReconciliation } from '@/services/paymentReconciliation';
import ReconciliationNotification from '@/components/ReconciliationNotification.vue';

// Safe environment access
const ENV = {
  IS_DEV: process.env.NODE_ENV === 'development',
  API_URL: process.env.VUE_APP_API_URL,
  ISPCUBE_URL: process.env.VUE_APP_URL_ISPCUBE,
  ENABLE_AMOUNT_EDIT: process.env.VUE_APP_ENABLE_AMOUNT_EDIT
};

export default {
  name: 'App',
  components: {
    NavBar,
    ReconciliationNotification
  },
  setup() {
    const router = useRouter();
    const versionCheckInterval = ref(null);
    const reconciliationCheckInterval = ref(null);
    const versionInfo = VersionService.getVersionInfo();
    const currentVersion = ref(versionInfo.version || '1.0.0');
    const showDevInfo = ref(ENV.IS_DEV);
    const apiUrl = ref(ENV.API_URL);
    const reconciliationStore = useReconciliationStore();

    // Computed to check if the user is logged in from the global state
    const isLoggedInComputed = computed(() => isLoggedIn.value);

    const checkForPendingReconciliations = async () => {
      if (!isLoggedIn.value) return; // Only check if logged in

      try {
        const reconciliation = new PaymentReconciliation();
        const pendingReconciliation = reconciliation.getPendingReconciliation();

        if (pendingReconciliation) {
          console.log('Found pending reconciliation:', pendingReconciliation);
          const reconciliationId = await reconciliationStore.startReconciliation(pendingReconciliation);

          try {
            const result = await reconciliation.reconcilePayment(pendingReconciliation);
            
            if (result.success) {
              await reconciliationStore.updateReconciliationStatus(reconciliationId, 'completed', result);
              console.log('Reconciliation completed successfully');
            } else {
              await reconciliationStore.updateReconciliationStatus(reconciliationId, 'failed', result);
              console.warn('Reconciliation failed:', result);
              
              if (result.canRetry) {
                console.log('Scheduling reconciliation retry');
                setTimeout(() => checkForPendingReconciliations(), 30000);
              }
            }
          } catch (error) {
            console.error('Error during reconciliation:', error);
            reconciliationStore.updateReconciliationStatus(reconciliationId, 'failed', {
              error: error.message
            });
          }
        }

        reconciliationStore.updateLastChecked();
      } catch (error) {
        console.error('Reconciliation check failed:', error);
        reconciliationStore.setError('Error checking for pending reconciliations');
      }
    };

    // Check session and version
    const checkSessionAndVersion = async () => {
      try {
        if (isLoggedIn.value) {
          const initialized = await VersionService.initialize();
          if (!initialized) {
            console.log('Version update required');
            
            const currentRoute = router.currentRoute.value.path;
            const isPaymentFlow = ['/pagarDeuda', '/confirmaCliente', '/pagoRealizado']
              .some(path => currentRoute.includes(path));

            if (isPaymentFlow) {
              alert('Se detectó una actualización de la aplicación. La página se recargará automáticamente.');
              
              const criticalData = {
                apiKeyIspcube: localStorage.getItem('apiKeyIspcube'),
                apiTokenIspcube: localStorage.getItem('apiTokenIspcube'),
                apiKeyPuntosdepago: localStorage.getItem('apiKeyPuntosdepago'),
                userRole: localStorage.getItem('userRole'),
                userid: localStorage.getItem('userid'),
                userName: localStorage.getItem('userName')
              };

              localStorage.clear();
              sessionStorage.clear();

              Object.entries(criticalData).forEach(([key, value]) => {
                if (value) localStorage.setItem(key, value);
              });

              sessionStorage.setItem('redirectToHome', 'true');
              setTimeout(() => window.location.reload(true), 500);
            } else {
              alert('Se detectó una actualización. La página se actualizará.');
              window.location.reload(true);
            }
          }
        }
      } catch (error) {
        console.error('Version check failed:', error);
      }
    };

    onMounted(async () => {
      if (sessionStorage.getItem('redirectToHome')) {
        sessionStorage.removeItem('redirectToHome');
        router.push('/');
        return;
      }

      if (ENV.IS_DEV) {
        console.log('Environment:', {
          ...ENV,
          VERSION: versionInfo
        });
      }

      await checkSessionAndVersion();
      await checkForPendingReconciliations(); // Initial reconciliation check

      // Set up intervals for checks
      versionCheckInterval.value = setInterval(() => {
        if (isLoggedIn.value) checkSessionAndVersion();
      }, 5 * 60 * 1000); // Every 5 minutes

      reconciliationCheckInterval.value = setInterval(() => {
        if (isLoggedIn.value) checkForPendingReconciliations();
      }, 2 * 60 * 1000); // Every 2 minutes
    });

    onBeforeUnmount(() => {
      if (versionCheckInterval.value) clearInterval(versionCheckInterval.value);
      if (reconciliationCheckInterval.value) clearInterval(reconciliationCheckInterval.value);
    });

    const handleLogout = async () => {
      try {
        isLoggedIn.value = false;
        localStorage.clear();
        sessionStorage.clear();
        router.push('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    return {
      isLoggedIn: isLoggedInComputed,
      currentVersion,
      showDevInfo,
      apiUrl,
      handleLogout,
      hasActiveReconciliation: computed(() => reconciliationStore.reconciliationInProgress)
    };
  }
};
</script>

<style>
.version-info {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  color: #666;
  background: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 4px;
  text-align: right;
}
</style>