
import { defineStore } from 'pinia';
import { createStoreOptions } from './config';

export const useReconciliationStore = defineStore(
  'reconciliation',
  createStoreOptions({
    state: () => ({
      activeReconciliations: [],
      notifications: [],
      history: [],
      status: null,
      error: null,
      lastChecked: null
    }),

    actions: {
      async startReconciliation(reconciliationData) {
        const reconciliationId = Date.now();
        
        this.status = 'in_progress';
        this.error = null;
        
        const reconciliation = {
          id: reconciliationId,
          startedAt: new Date(),
          paymentData: {
            userid: reconciliationData.paymentData.userid,
            idcustomer: reconciliationData.paymentData.idcustomer,
            importe: reconciliationData.paymentData.importe,
            codigoCobranza: reconciliationData.paymentData.codigoCobranza
          },
          status: 'started',
          attempts: 0
        };

        this.activeReconciliations.push(reconciliation);
        this.addNotification({
          type: 'info',
          message: 'Verificando pago pendiente...',
          reconciliationId
        });

        return reconciliationId;
      },

      async updateReconciliationStatus(id, status, result = null) {
        const reconciliation = this.activeReconciliations.find(r => r.id === id);
        if (reconciliation) {
          reconciliation.status = status;
          reconciliation.attempts++;
          
          if (result) {
            reconciliation.result = result;
          }

          if (status === 'completed' || status === 'failed') {
            this.finalizeReconciliation(id, status, result);
          }
        }
      },

      finalizeReconciliation(id, status, result) {
        const reconciliation = this.activeReconciliations.find(r => r.id === id);
        if (reconciliation) {
          reconciliation.completedAt = new Date();
          this.history.push({ ...reconciliation });
          this.activeReconciliations = this.activeReconciliations.filter(r => r.id !== id);
          
          if (this.activeReconciliations.length === 0) {
            this.status = null;
          }

          this.addNotification({
            type: status === 'completed' ? 'success' : 'error',
            message: status === 'completed' 
              ? 'Pago verificado exitosamente'
              : `Error en la verificación del pago: ${result?.error || 'Error desconocido'}`,
            reconciliationId: id,
            autoClose: true
          });
        }
      },

      addNotification(notification) {
        const id = Date.now();
        this.notifications.push({
          id,
          timestamp: new Date(),
          ...notification
        });

        if (notification.autoClose) {
          setTimeout(() => {
            this.removeNotification(id);
          }, 5000);
        }
      },

      removeNotification(id) {
        this.notifications = this.notifications.filter(n => n.id !== id);
      },

      setError(error) {
        this.error = error;
        this.addNotification({
          type: 'error',
          message: error,
          autoClose: true
        });
      },

      updateLastChecked() {
        this.lastChecked = new Date();
      },

      clearHistory() {
        this.history = [];
      }
    },

    getters: {
      hasActiveReconciliations: (state) => state.activeReconciliations.length > 0,
      reconciliationInProgress: (state) => state.status === 'in_progress',
      currentNotifications: (state) => state.notifications,
      lastReconciliationResult: (state) => state.history[state.history.length - 1],
      pendingReconciliations: (state) => state.activeReconciliations.filter(r => r.status === 'started'),
      failedReconciliations: (state) => state.activeReconciliations.filter(r => r.status === 'failed')
    },

    persist: {
      paths: ['history', 'lastChecked'] // Only persist specific paths
    }
  })
);