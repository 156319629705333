
//const   IspCube = process.env.VUE_APP_URL_ISPCUBE || 'http://168.194.140.69/apispcube/public';
const urlIspCube = process.env.VUE_APP_URL_ISPCUBE;


export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

//let isDepositoInProgress = false; // Ensure this is declared globally

/*
export async function realizaDeposito(idcustomer, importe, retryCount = 0) {
  if (isDepositoInProgress) {
    console.warn("realizaDeposito is already in progress, skipping...");
    return; // Return immediately if a deposit is already in progress
  }

  console.log("realizaDeposito started");
  isDepositoInProgress = true; // Set the flag to prevent multiple calls

  const nombre = localStorage.getItem("nombre");
  const userid = localStorage.getItem("userid");
  console.log("nombre y userid en realizaDeposito:", nombre, userid);

  const url = `${urlIspCube}/index.php/cashs`;
  const data = {
    user: userid,
    idcustomer: idcustomer,
    amount: importe
  };

  const myHeaders = generaHeadersHTTP();

  try {
    // External API Request
    console.log("Sending to external API:", url, data);
    let response = await fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
    });

    console.log("External API response status:", response.status);
    if (!response.ok) {
      if (response.status >= 500 && retryCount < 3) {
        console.warn("Server error, retrying payment in 3 seconds...");
        setTimeout(() => realizaDeposito(idcustomer, importe, retryCount + 1), 3000);
        return;
      }
      throw new Error('Network response was not ok');
    }

    let responseText = await response.text();
    console.log('realizaDeposito raw response:', responseText);

    let responseData;
    try {
      responseData = JSON.parse(responseText);
    } catch (error) {
      console.warn('Response is not valid JSON, treating as plain text:', responseText);
      responseData = responseText; // Use the raw response text
    }

    console.log('realizaDeposito Success codigoCobranzaDevuelto:', responseData);
    localStorage.setItem("codigoCobranzaDevuelto", responseData);

    // Prepare local data for insertion
    const localData = {
      userid: userid,
      idcustomer: idcustomer,
      fecha: getFormattedDateTimeInTimeZone(-3),
      codigocobranza: responseData,
      importe: importe
    };

    console.log("Inserting operation into local database with data:", localData);

    // Local Database Insertion
    let localResponse = await fetch(`${process.env.VUE_APP_API_URL}/operaciones`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Ensure session cookies are included
      body: JSON.stringify(localData)
    });

    console.log("Local API response status:", localResponse.status);
    if (!localResponse.ok) {
      if (localResponse.status >= 500 && retryCount < 3) {
        console.warn("Local server error, retrying payment in 3 seconds...");
        setTimeout(() => realizaDeposito(idcustomer, importe, retryCount + 1), 3000);
        return;
      }
      throw new Error('Failed to insert operation into local database');
    }

    let localResponseData = await localResponse.json();
    console.log('Local database insert success:', localResponseData);

  } catch (error) {
    console.error('realizaDeposito Error:', error.stack || error);
    if (retryCount >= 3) {
      alert("Payment postponed, please check your connection and try again.");
    }
  } finally {
    isDepositoInProgress = false; // Reset the flag after the process finishes
    console.log("realizaDeposito finished");
  }
}
*/

/*export async function procesaDeposito() {
  const clienteIdCustomer = localStorage.getItem("clienteIdCustomer");
  const totalDepositado = parseFloat(localStorage.getItem("totalDepositado")) || 0;
  await realizaDeposito(clienteIdCustomer, totalDepositado);
}*/

export function postergaDeposito() {
  const totalDepositado = parseFloat(localStorage.getItem("totalDepositado")) || 0;

  if (totalDepositado > 0) {
   // window.JL("totemWebApp").info("No hay conexion con el servidor. Queda pendiente de depositar: " + totalDepositado);
    localStorage.setItem("estadoDeposito", "pendiente");
  } else {
   // window.JL("totemWebApp").info("total depositado: " + totalDepositado + " (no hay nada que depositar)");
    localStorage.setItem("estadoDeposito", "realizado");
  }
}

/*
export async function procesaDepositoPendiente() {
    let hayConexion = localStorage.getItem("hayConexion");
    let estadoDeposito = localStorage.getItem("estadoDeposito");

    //const requestId = localStorage.getItem("requestId");
   // window.JL.setOptions({ "requestId": requestId });

    if (hayConexion === "true") {
        if (estadoDeposito === "pendiente") {
            console.log("Hay una transacción pendiente");

            const clienteIdCustomer = localStorage.getItem("clienteIdCustomer");
            const totalDepositado = localStorage.getItem("totalDepositado");

         //actualizar a servicio payment
         //   await realizaDeposito(clienteIdCustomer, totalDepositado);

            await sleep(1000); // Para darle tiempo a que devuelva el código de cobranza

        //    window.JL("totemWebApp").info("Se procesa un depósito que había quedado pendiente");
        //    window.JL("totemWebApp").info("Total depositado: " + totalDepositado);
        //    window.JL("totemWebApp").info("Código cobranza: " + localStorage.getItem("codigoCobranzaDevuelto"));
        //    window.JL("totemWebApp").info("######## FIN (ya no hay depósito pendiente) ###########");
//
            localStorage.clear();
            console.log("Se procesó la transacción pendiente");
        } else if (estadoDeposito === "realizado") {
            console.log("No hay transacción pendiente");
            localStorage.clear();
        } else {
            console.log("No había transacción pendiente");
            localStorage.clear();
        }
    }
}
*/

export function fetchConTimeout(url, options, timeout = 4000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('timeout')), timeout)
    )
  ]);
}


export function chequeaServidor() {
  const url = urlIspCube;
  return fetchConTimeout(url, {}, 5000)  // Adjust timeout as necessary
    .then(() => {
      console.log('Server connection successful.');
      localStorage.setItem("hayConexion", "true");
    })
    .catch((error) => {
      console.error('Server connection failed:', error.message || error);
      localStorage.setItem("hayConexion", "false");
    });
}



export async function guardaClienteSeleccionado() {
  console.log('guardaClienteSeleccionado in');
  localStorage.setItem('usernameingresado', this.dni);
  const idCliente = localStorage.getItem('usernameingresado');
  await guardaDatosCliente(idCliente);
  console.log('id: ' + idCliente);
  await this.sleep(1000);
  this.$router.push('/confirmaCliente');
}

export async function obtieneDatosCliente(idCliente, headers) {
  const url = `${urlIspCube}/index.php/customers?page=1&limit=1&q=${idCliente}`;
  
  try {
      const response = await fetch(url, {
          method: 'GET',
          headers: headers,
          mode: 'cors',
          cache: 'default',
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const datosCliente = await response.json();
      return datosCliente;
  } catch (error) {
      console.error("Error fetching client data:", error);
      throw error;
  }
}



export async function guardaDatosCliente(idCliente) {
  try {
      const myHeaders = generaHeadersHTTP(); 
      const result = await obtieneDatosCliente(idCliente, myHeaders);

      localStorage.setItem("clienteNombre", result.data[0].name);
      localStorage.setItem("clienteIdCustomer", result.data[0].idcustomer);
      localStorage.setItem("clienteDireccion", result.data[0].address);
      localStorage.setItem("clienteCiudad", result.data[0].city);
      localStorage.setItem("clienteProvincia", result.data[0].state);
      localStorage.setItem("clienteCondicionIVA", result.data[0].nameTaxType);
      localStorage.setItem("clienteDeuda", result.data[0].debt * -1);

      console.log("Client data successfully saved to localStorage");
  } catch (error) {
      console.error("Error saving client data:", error);
  }
}

// This function generates headers for ISPcube API
export function generaHeadersHTTP() {
  console.log("Inside generaHeadersHTTP");

  const myHeaders = new Headers();
  const apiKeyIspcube = localStorage.getItem("apiKeyIspcube");
  const apiTokenIspcube = localStorage.getItem("apiTokenIspcube");

  // Debugging to confirm the values are correctly retrieved from localStorage
  console.log("apiKeyIspcube from localStorage:", apiKeyIspcube);
  console.log("apiTokenIspcube from localStorage:", apiTokenIspcube);

  if (apiKeyIspcube && apiTokenIspcube) {
    console.log("Using ISPcube API Key and Token");
    myHeaders.append('api-key', apiKeyIspcube);  // Reverting to 'api-key'
    myHeaders.append('api-token', apiTokenIspcube);  // Reverting to 'api-token'
  } else {
    console.warn("ISPcube API Key or Token is missing");
  }

  myHeaders.append('Content-Type', 'application/json');
  
  // Log the headers explicitly before sending them
  myHeaders.forEach((value, key) => {
    console.log(`Header - ${key}: ${value}`);
  });

  return myHeaders;
}




// For the PuntosDePago API key
export function generaHeadersPuntosDePago() {
  console.log("Inside generaHeadersPuntosDePago");

  const myHeaders = new Headers();
  const apiKeyPuntosDePago = localStorage.getItem("apiKeyPuntosDePago");

  if (apiKeyPuntosDePago) {
    console.log("Using PuntosDePago API Key");
    myHeaders.append('api-key-puntosdepago', apiKeyPuntosDePago);
  } else {
    console.warn("PuntosDePago API Key is missing");
  }

  myHeaders.append('Content-Type', 'application/json');
  console.log("Generated Headers for PuntosDePago:", myHeaders);
  return myHeaders;
}


export function getFormattedDateTimeInTimeZone(offset) {
    const localDate = new Date();
    const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000));
    const targetDate = new Date(utcDate.getTime() + (offset * 3600000));
    
    const year = targetDate.getFullYear();
    const month = String(targetDate.getMonth() + 1).padStart(2, '0');
    const day = String(targetDate.getDate()).padStart(2, '0');
    const hours = String(targetDate.getHours()).padStart(2, '0');
    const minutes = String(targetDate.getMinutes()).padStart(2, '0');
    const seconds = String(targetDate.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
