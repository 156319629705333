<template>
    <div id="recibo" ref="reciboContent">
      
        <h2>SWISSNET</h2>
        José Rivera 440<br>
        N3328EFO<br>
        Jardín América - Misiones<br>
        0810-555-7947 (lin. rot.)<br>
        info@swiss-net.com.ar<br>
        www.swiss-net.com.ar
        <hr>
    
        <h3>Recibo X</h3>
        no válido como factura<br>
        CUIT: 30-71665558-6<br>
        ING. BRUTOS: 30-71665558-6<br>
        <hr>
     
    
      Fecha de Emisión: <label>{{ fechahora }}</label><br>
      Código Operación: <label>{{ codigoCobranzaDevuelto }}</label>
      <hr>
    
      <b>Cliente:</b> <label>{{ nombreCliente }}</label><br>
      <label>{{ condicionIVACliente }}</label><br>
      <b>Dirección:</b> <label>{{ direccionCliente }}</label><br>
      <b>Ciudad:</b> <label>{{ ciudadCliente }}</label>
      <hr>
    
      <b>Recibí la suma de: $</b> <label>{{ totalDepositado }}</label><br>
      <u>En concepto de:</u> Pago<br><br>
    
      Saldo en Cuenta: $ <label>{{ saldoNuevo }}</label><br><br>
    </div>
  </template>
  
  <script>
  import printJS from 'print-js';
  
  export default {
    data() {
      return {
        fechahora: '',
        codigoCobranzaDevuelto: '',
        nombreCliente: '',
        condicionIVACliente: '',
        direccionCliente: '',
        ciudadCliente: '',
        totalDepositado: 0,
        saldoNuevo: 0,
      };
    },
    methods: {
      getFormattedDateTime() {
        return new Date().toLocaleString('es-AR');
      },
      loadReceiptData() {
        this.nombreCliente = `&nbsp; ${localStorage.getItem('clienteNombre') || ''}`;
        this.totalDepositado = `&nbsp; ${localStorage.getItem('totalDepositado') || 0}`;
        this.saldoNuevo = `&nbsp; ${localStorage.getItem('saldoNuevo') || 0}`;
        this.fechahora = this.getFormattedDateTime();
        this.direccionCliente = `&nbsp; ${localStorage.getItem('clienteDireccion') || ''}`;
        this.condicionIVACliente = `&nbsp; ${localStorage.getItem('clienteCondicionIVA') || ''}`;
        this.ciudadCliente = `&nbsp; ${localStorage.getItem('clienteCiudad') || ''}`;
        this.codigoCobranzaDevuelto = `&nbsp; ${localStorage.getItem('codigoCobranzaDevuelto') || 'depósito pendiente de procesar'}`;
  
        console.log('codigoCobranzaDevuelto:', this.codigoCobranzaDevuelto);
  
        // The JSNLog (JL) part has been commented out since logging was disabled
        /*
        const requestId = localStorage.getItem("requestId");
        JL.setOptions({ "requestId": requestId });
        JL("totemWebApp").info("codigo cobranza: " + this.codigoCobranzaDevuelto);
        JL("totemWebApp").info("Se imprime recibo.");
        */
      },
      printReceipt() {
        printJS({ printable: this.$refs.reciboContent.innerHTML, type: 'raw-html' });
      }
    },
    mounted() {
      this.loadReceiptData();
      this.printReceipt();
    }
  };
  </script>
  
  <style scoped>
  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '@/css/starter-template.css';
  @import '@/css/estilosCompartidos.css';
  
  /* Set the width of the receipt to fit within the 80mm width */
  #recibo {
    background-color: rgb(127, 163, 255);
    width: 80mm; /* Ensures the receipt fits within the 80mm width */
    margin: 0 auto; /* Centers the receipt */
    padding: 5mm; /* Adds some padding */
    font-family: 'monospace'; /* Monospace font for better alignment */
    font-size: 12px; /* Adjust font size to fit better */
    word-wrap: break-word; /* Ensures long words break to fit within the width */
  }
  
  /* Additional styles for better spacing */
  #recibo hr {
    margin: 5px 0; /* Reduce margin for <hr> elements */
  }
  
  #recibo h2, #recibo h3 {
    margin: 0;
  }
  
  #recibo label {
    display: inline-block;
    width: 100%; /* Ensure labels take the full width */
    text-align: left;
  }
  
  /* Smaller fonts for certain details */
  #recibo small {
    font-size: 10px;
  }
  
  </style>
  