import { PaymentAvailabilityService } from '@/services/PaymentAvailabilityService';

export class PaymentService {
    constructor() {
        this.baseUrl = process.env.VUE_APP_API_URL;
        this.transactionEndpoint = `${this.baseUrl}/payments`;
        this.availabilityService = new PaymentAvailabilityService();
        // Test mode configuration
        this._testMode = {
            enabled: process.env.NODE_ENV === 'development',
            simulateBackendFailure: false,
            simulateISPcubeFailure: false,
            simulateNetworkError: false
        };
        
        console.log('PaymentService initialized:', {
            baseUrl: this.baseUrl,
            transactionEndpoint: this.transactionEndpoint,
            testMode: this._testMode
        });
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'api-key-puntosdepago': localStorage.getItem('apiKeyPuntosdepago'),
            'Cache-Control': 'no-cache'
        };
    }
    // Test mode configuration methods
    setTestMode(options = {}) {
        if (process.env.NODE_ENV === 'development') {
            this._testMode = {
                ...this._testMode,
                ...options,
                enabled: true
            };
            console.log('Test mode updated:', this._testMode);
        } else {
            console.warn('Test mode is only available in development environment');
        }
    }

    resetTestMode() {
        this._testMode = {
            enabled: process.env.NODE_ENV === 'development',
            simulateBackendFailure: false,
            simulateISPcubeFailure: false,
            simulateNetworkError: false
        };
        console.log('Test mode reset:', this._testMode);
    }

    async processPayment(paymentData) {
        if (!await this.availabilityService.canProcessNewPayments()) {
            throw new Error('Cannot process new payments until pending transactions are reconciled');
        }
    
        let pendingTransaction = null;
        let ispcubeResult = null;
    
        try {
            // 1. Create pending transaction
            try {
                pendingTransaction = await this.createPendingTransaction(paymentData);
            } catch (backendError) {
                if (this._testMode.simulateBackendFailure) {
                    // Simulate a successful ISPcube response despite backend failure
                    ispcubeResult = await this.processISPcubePayment(paymentData);
                    return {
                        success: false,
                        requiresReconciliation: true,
                        isProvisional: true,
                        ispcubeResponse: ispcubeResult,
                        codigoCobranza: ispcubeResult.codigoCobranza,
                        transactionId: `test_${Date.now()}`
                    };
                }
                throw new Error('Backend unavailable. Please try again later.');
            }
    
            // 2. Process ISPcube payment
            ispcubeResult = await this.processISPcubePayment(paymentData);
            
            try {
                // 3. Try to update backend with ISPcube response
                await this.updateTransactionWithISPcubeResponse(
                    pendingTransaction.transactionId, 
                    {
                        ...ispcubeResult,
                        requiresReconciliation: this._testMode.simulateBackendFailure
                    }
                );
    
                // Simulate backend failure after ISPcube success if in test mode
                if (this._testMode.simulateBackendFailure) {
                    throw new Error('Simulated backend failure');
                }
    
                // 4. Try to complete transaction
                const result = await this.completeTransaction(
                    pendingTransaction.transactionId, 
                    ispcubeResult.codigoCobranza
                );
    
                return {
                    ...result,
                    success: true,
                    codigoCobranza: ispcubeResult.codigoCobranza,
                    transactionId: pendingTransaction.transactionId,
                    requiresReconciliation: false
                };
    
            } catch (backendError) {
                // Store reconciliation data if backend fails after ISPcube success
                const reconciliationData = {
                    transactionId: pendingTransaction.transactionId,
                    codigoCobranza: ispcubeResult.codigoCobranza,
                    timestamp: new Date().toISOString(),
                    paymentData,
                    ispcubeResponse: ispcubeResult
                };
    
                this.availabilityService.storePendingTransaction(reconciliationData);
    
                return {
                    success: false,
                    requiresReconciliation: true,
                    isProvisional: true,
                    ispcubeResponse: ispcubeResult,
                    transactionId: pendingTransaction.transactionId,
                    codigoCobranza: ispcubeResult.codigoCobranza
                };
            }
    
        } catch (error) {
            console.error('Payment processing error:', error);
            throw error;
        }
    }
    
    

    async createPendingTransaction(paymentData) {
        const response = await fetch(`${this.transactionEndpoint}/pending`, {
            method: 'POST',
            headers: this.getHeaders(),
            credentials: 'include',
            body: JSON.stringify(paymentData)
        });

        if (!response.ok) {
            const error = new Error('Failed to create pending transaction');
            error.name = 'TransactionError';
            throw error;
        }

        return await response.json();
    }

    async updateTransactionStatus(transactionId, status) {
        const response = await fetch(`${this.transactionEndpoint}/${transactionId}/status`, {
            method: 'PUT',
            headers: this.getHeaders(),
            credentials: 'include',
            body: JSON.stringify({ status })
        });

        if (!response.ok) {
            console.warn(`Failed to update transaction status to ${status}`);
            // Don't throw - this is not critical
        }
    }

    async processISPcubePayment(paymentData) {
        try {
            const response = await fetch(`${process.env.VUE_APP_URL_ISPCUBE}/index.php/cashs`, {
                method: 'POST',
                headers: {
                    'api-key': localStorage.getItem('apiKeyIspcube'),
                    'api-token': localStorage.getItem('apiTokenIspcube'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: paymentData.userid,
                    idcustomer: paymentData.idcustomer,
                    amount: paymentData.importe
                })
            });

            if (!response.ok) {
                const error = new Error('ISPcube payment failed');
                error.name = 'ISPcubeError';
                throw error;
            }

            const codigoCobranza = await response.text();
            return { success: true, codigoCobranza };
        } catch (error) {
            error.name = 'ISPcubeError';
            throw error;
        }
    }

   
    async updateTransactionWithISPcubeResponse(transactionId, ispcubeResult) {
        const response = await fetch(`${this.transactionEndpoint}/${transactionId}/ispcube`, {
            method: 'PUT',
            headers: this.getHeaders(),
            credentials: 'include',
            body: JSON.stringify({
                ...ispcubeResult,
                requiresReconciliation: this._testMode.simulateBackendFailure
            })
        });
    
        if (!response.ok) {
            const error = new Error('Failed to update transaction with ISPcube response');
            error.name = 'TransactionError';
            throw error;
        }
    
        return await response.json();
    }


    async completeTransaction(transactionId, codigoCobranza) {
        const response = await fetch(
            `${this.transactionEndpoint}/${transactionId}/complete`, {
                method: 'PUT',
                headers: this.getHeaders(),
                credentials: 'include',
                body: JSON.stringify({ codigoCobranza })
            }
        );

        if (!response.ok) {
            const error = new Error('Failed to complete transaction');
            error.name = 'TransactionError';
            throw error;
        }

        return await response.json();
    }

    getFriendlyErrorMessage(errorMessage) {
        const errorMap = {
            'Failed to fetch': 'Error de conexión. Por favor, verifique su conexión a internet.',
            'Network error': 'Error de red. Por favor, verifique su conexión.',
            'timeout': 'La operación ha excedido el tiempo límite.',
            'api-key': 'Error de autenticación. Por favor, inicie sesión nuevamente.',
            'ISPcubeError': 'Error procesando el pago. Por favor, intente nuevamente.',
            'TransactionError': 'Error en el sistema. El pago será verificado automáticamente.'
        };

        for (const [key, value] of Object.entries(errorMap)) {
            if (errorMessage.toLowerCase().includes(key.toLowerCase())) {
                return value;
            }
        }

        return 'Error inesperado. Por favor, contacte a soporte.';
    }
}