<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <div class="starter-template text-center py-5 px-3">
        <div class="row">
          <!-- Receipt Column -->
          <div class="col-md-8">
            <div class="card text-center">
              <div class="card-body">
                <div id="cierreReceipt">
                  <ResumenCierreCajaReceipt
                    :user-id="userId"
                    :formatted-date="formattedDate"
                    :num-operations="numOperations"
                    :total-amount="totalAmount"
                    :commission-amount="commissionAmount"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Buttons Column -->
          <div class="col-md-4 d-flex flex-column justify-content-center">
            <button @click="printReceipt" class="btn btn-primary mb-3">Imprimir Resumen</button>
            <button @click="finishTransaction" class="btn btn-secondary">Finalizar</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ResumenCierreCajaReceipt from '@/components/ResumenCierreCajaReceipt.vue';

export default {
  components: {
    ResumenCierreCajaReceipt,
  },
  data() {
    return {
      userId: '',
      formattedDate: '',
      numOperations: 0,
      totalAmount: 0,
      commissionAmount: 0,
    };
  },
  methods: {
    printReceipt() {
      window.print();
    },
    finishTransaction() {
      this.$router.push('/cierreDeCaja');
    },
    getArgentinaDateTime() {
      return new Intl.DateTimeFormat('es-AR', {
        timeZone: 'America/Argentina/Buenos_Aires',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).format(new Date());
    }
  },
  mounted() {
    // Get the query parameters and set values
    this.userId = this.$route.query.userId || 'Usuario Desconocido';
    this.formattedDate = this.getArgentinaDateTime();
    this.numOperations = parseInt(this.$route.query.numOperations) || 0;
    this.totalAmount = parseFloat(this.$route.query.totalAmount) || 0;
    this.commissionAmount = parseFloat(this.$route.query.commissionAmount) || 0;

    console.log('Receipt mounted with:', {
      userId: this.userId,
      formattedDate: this.formattedDate,
      numOperations: this.numOperations,
      totalAmount: this.totalAmount,
      commissionAmount: this.commissionAmount
    });
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/login.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

@media print {
  .btn, .card-title {
    display: none !important;
  }

  .container, .row, .col-md-8, .card, .card-body {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  #cierreReceipt {
    width: 80mm !important;
    margin: 0 auto !important;
  }
}
</style>