<template>
    <div class="p-4">
      <div class="card">
        <div class="card-body">
          <h2 class="card-title mb-4">Pruebas de pagos</h2>
          
          <!-- Statistics Dashboard -->
          <div class="grid grid-cols-4 gap-4 mb-6">
            <div v-for="stat in stats" :key="stat.label" 
              class="p-4 rounded shadow"
              :class="stat.bgClass">
              <div class="text-3xl font-bold" :class="stat.textClass">
                {{ stat.value }}
              </div>
              <div class="text-gray-600">{{ stat.label }}</div>
            </div>
          </div>
  
          <!-- Control Panel -->
          <div class="flex gap-4 mb-6">
            <button
              @click="runAllTests"
              :disabled="isRunning"
              class="btn btn-primary"
            >
              {{ isRunning ? 'Ejecutando pruebas...' : 'Ejecutar todas las pruebas' }}
            </button>
            <button
              @click="clearResults"
              :disabled="isRunning"
              class="btn btn-secondary"
            >
              Borrar resultados
            </button>
          </div>
  
          <!-- Test Cases -->
          <div class="space-y-4">
            <div v-for="test in paymentScenarios" 
              :key="test.id" 
              class="border rounded p-4">
              <div class="flex justify-between items-start mb-4">
                <div>
                  <h3 class="text-xl font-semibold">{{ test.name }}</h3>
                  <p class="text-gray-600">{{ test.description }}</p>
                </div>
                <div class="flex items-center gap-4">
                  <button
                    @click="runSingleTest(test.id)"
                    :disabled="isRunning"
                    class="btn btn-sm btn-primary"
                  >
                    {{ isSelectedTest(test.id) ? 'Ejecutando...' : 'Ejecutar prueba' }}
                  </button>
                  <span v-if="getTestResult(test.id)"
                    class="px-2 py-1 rounded text-sm"
                    :class="getStatusClass(getTestResult(test.id)?.status)"
                  >
                    {{ getTestResult(test.id)?.status }}
                  </span>
                </div>
              </div>
  
              <!-- Test Steps -->
              <div class="space-y-2">
                <div v-for="(step, index) in test.steps" 
                  :key="index"
                  class="flex items-center gap-2">
                  <div class="w-2 h-2 rounded-full"
                    :class="getStepStatusClass(test.id, index)"/>
                  <span :class="{
                    'text-blue-600 font-medium': 
                      getTestResult(test.id)?.currentStep === index,
                    'text-gray-600': 
                      getTestResult(test.id)?.currentStep !== index
                  }">
                    {{ step }}
                  </span>
                </div>
              </div>
  
              <!-- Test Result Data -->
              <template v-if="getTestResult(test.id)">
                <div v-if="getTestResult(test.id).error" 
                  class="mt-4 bg-red-50 text-red-800 p-4 rounded">
                  {{ getTestResult(test.id).error }}
                </div>
                <div v-if="getTestResult(test.id).data" 
                  class="mt-4 bg-gray-50 p-4 rounded">
                  <pre class="text-sm overflow-auto">{{ 
                    JSON.stringify(getTestResult(test.id).data, null, 2) 
                  }}</pre>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { PaymentTestService } from '../services/PaymentTestService';
  import { paymentScenarios } from '../scenarios/paymentScenarios';
  
  const testResults = ref([]);
  const isRunning = ref(false);
  const selectedTest = ref(null);
  const testService = new PaymentTestService();
  
  // Stats computed property
  const stats = computed(() => [
    {
      label: 'Total Tests',
      value: paymentScenarios.length,
      bgClass: 'bg-white',
      textClass: 'text-dark'
    },
    {
      label: 'Correcto',
      value: testResults.value.filter(r => r.status === 'success').length,
      bgClass: 'bg-green-50',
      textClass: 'text-green-600'
    },
    {
      label: 'Error',
      value: testResults.value.filter(r => r.status === 'failed').length,
      bgClass: 'bg-red-50',
      textClass: 'text-red-600'
    },
    {
      label: 'Ejecutando',
      value: testResults.value.filter(r => r.status === 'running').length,
      bgClass: 'bg-blue-50',
      textClass: 'text-blue-600'
    }
  ]);
  
  const getTestResult = (id) => {
    return testResults.value.find(r => r.id === id);
  };
  
  const isSelectedTest = (id) => {
    return selectedTest.value === id;
  };
  
  const getStatusClass = (status) => {
    const classes = {
      success: 'bg-green-100 text-green-800',
      failed: 'bg-red-100 text-red-800',
      running: 'bg-blue-100 text-blue-800'
    };
    return classes[status] || 'bg-gray-100 text-gray-800';
  };
  
  const getStepStatusClass = (testId, stepIndex) => {
    const result = getTestResult(testId);
    if (!result) return 'bg-gray-300';
    
    if (result.currentStep > stepIndex) return 'bg-green-500';
    if (result.currentStep === stepIndex) return 'bg-blue-500';
    return 'bg-gray-300';
  };
  
  const runSingleTest = async (testId) => {
    isRunning.value = true;
    selectedTest.value = testId;
    try {
      const scenario = paymentScenarios.find(s => s.id === testId);
      const result = {
        id: scenario.id,
        name: scenario.name,
        status: 'running',
        currentStep: 0,
        startTime: Date.now()
      };
      
      await testService.runTest(scenario, result);
      testResults.value = [
        ...testResults.value.filter(r => r.id !== testId),
        result
      ];
    } finally {
      isRunning.value = false;
      selectedTest.value = null;
    }
  };
  
  const runAllTests = async () => {
    isRunning.value = true;
    try {
      const results = await testService.runAllTests(paymentScenarios);
      testResults.value = results;
    } finally {
      isRunning.value = false;
    }
  };
  
  const clearResults = () => {
    testResults.value = [];
    selectedTest.value = null;
  };
  </script>
  
  <style scoped>
  .grid-cols-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .btn {
    @apply px-4 py-2 rounded font-medium transition-colors;
  }
  
  .btn-primary {
    @apply bg-blue-600 text-white hover:bg-blue-700 disabled:bg-gray-400;
  }
  
  .btn-secondary {
    @apply bg-gray-600 text-white hover:bg-gray-700 disabled:bg-gray-400;
  }
  
  .btn-sm {
    @apply px-3 py-1 text-sm;
  }
  
  .status-indicator {
    width: 8px;
    height: 8px;
    @apply rounded-full;
  }
  </style>