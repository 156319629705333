// stores/config.js
export const piniaPluginConfig = {
    persist: {
        key: (id) => `${id}-store`,
        storage: localStorage,
        debug: process.env.NODE_ENV === 'development',
        beforeRestore: (ctx) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(`About to restore '${ctx.store.$id}'`);
            }
        },
        afterRestore: (ctx) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(`Restored '${ctx.store.$id}'`);
            }
        }
    }
};

export const createStoreOptions = (options) => ({
    ...options,
    persist: {
        ...piniaPluginConfig.persist,
        ...options.persist
    }
});