export default {
  data() {
    return {
      isLoggedIn: false,
      userRole: '',
    };
  },
  methods: {
    async checkSession() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/checkSession`, {
          method: 'GET',
          credentials: 'include',
        });
        
        const data = await response.json();
        
        if (data.loggedin) {
          this.isLoggedIn = true;
          this.userRole = localStorage.getItem('userRole') || data.role;
          localStorage.setItem('userRole', this.userRole);
        } else {
          this.$router.push('/login');
        }
      } catch (error) {
        console.error('Error checking session:', error);
        this.$router.push('/login'); // Redirect to login on error
      }
    },
  },
  mounted() {
    this.checkSession(); // Ensure session is checked when components are mounted
  }
};
