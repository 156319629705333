<template>
  <div class="listar-operaciones">
    <main class="container">
      <div class="starter-template py-5 px-3">
        <div class="row">
          <!-- Filters Column -->
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <h3 style="font-size:2rem">Filtrar Operaciones</h3>

                <label for="status">Estado</label>
                <select 
                  v-model="filters.status" 
                  id="status" 
                  class="form-control"
                  @change="handleFilterChange"
                >
                  <option value="">Todas</option>
                  <option value="open">Abiertas</option>
                  <option value="closed">Cerradas</option>
                </select>

                <!-- Admin Only: Filter by User ID -->
                <div v-if="isAdmin">
                  <label for="userId">Usuario</label>
                  <select
                    v-model="filters.userId"
                    id="userId"
                    class="form-control"
                    @change="handleFilterChange"
                  >
                    <option value="">Todos los usuarios</option>
                    <option 
                      v-for="user in users" 
                      :key="user.userid" 
                      :value="user.userid"
                    >
                      {{ user.nombre }}
                    </option>
                  </select>
                </div>

                <br />
                <label for="startDate">Desde</label>
                <input 
                  type="date" 
                  v-model="filters.startDate" 
                  id="startDate" 
                  class="form-control"
                  @change="handleFilterChange"
                />

                <label for="endDate">Hasta</label>
                <input 
                  type="date" 
                  v-model="filters.endDate" 
                  id="endDate" 
                  class="form-control"
                  @change="handleFilterChange"
                />
              </div>
            </div>
          </div>

          <!-- Results Column -->
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <!-- Sort Controls -->
                <div class="sort-controls mb-3">
                  <div class="d-flex justify-content-end gap-3">
                    <div 
                      class="sort-header" 
                      @click="handleSort('fecha')"
                      :class="{ active: sortBy === 'fecha' }"
                    >
                      Fecha 
                      <span v-if="sortBy === 'fecha'" class="sort-indicator">
                        {{ sortDirection === 'asc' ? '↑' : '↓' }}
                      </span>
                    </div>
                    <div 
                      class="sort-header" 
                      @click="handleSort('importe')"
                      :class="{ active: sortBy === 'importe' }"
                    >
                      Monto 
                      <span v-if="sortBy === 'importe'" class="sort-indicator">
                        {{ sortDirection === 'asc' ? '↑' : '↓' }}
                      </span>
                    </div>
                    <div 
                      class="sort-header" 
                      @click="handleSort('status')"
                      :class="{ active: sortBy === 'status' }"
                    >
                      Estado 
                      <span v-if="sortBy === 'status'" class="sort-indicator">
                        {{ sortDirection === 'asc' ? '↑' : '↓' }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="loading" class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Cargando...</span>
                  </div>
                </div>

                <ul class="list-group mb-3" v-else-if="operations.length > 0">
                  <li
                    v-for="operation in sortedOperations"
                    :key="operation.idoperacion"
                    class="list-group-item d-flex justify-content-between lh-condensed"
                  >
                    <div>
                      <h2 class="my-0">Operación ID: {{ operation.idoperacion }}</h2>
                      <p class="text-muted resultados" v-if="isAdmin">
                        Usuario: {{ operation.userid }}
                      </p>
                      <p class="text-muted resultados">
                        Monto: ${{ formatAmount(operation.importe) }}
                      </p>
                      <p class="text-muted resultados">
                        Fecha: {{ formatDate(operation.fecha) }}
                      </p>
                      <p class="text-muted resultados">
                        Estado: 
                        <span :class="getStatusClass(operation.status)">
                          {{ operation.status }}
                        </span>
                      </p>
                    </div>
                  </li>
                </ul>
                
                <div v-else class="text-center py-4">
                  <p>No se encontraron operaciones.</p>
                </div>

                <!-- Pagination -->
                <div class="d-flex justify-content-between align-items-center mt-4">
                  <div class="items-per-page">
                    <select 
                      v-model="itemsPerPage" 
                      class="form-control form-control-sm"
                      @change="handlePageSizeChange"
                    >
                      <option :value="10">10 por página</option>
                      <option :value="25">25 por página</option>
                      <option :value="50">50 por página</option>
                    </select>
                  </div>

                  <nav aria-label="Page navigation" v-if="totalPages > 1">
                    <ul class="pagination mb-0">
                      <li 
                        class="page-item"
                        :class="{ disabled: currentPage === 1 }"
                      >
                        <button 
                          class="page-link" 
                          @click="changePage(currentPage - 1)"
                          :disabled="currentPage === 1"
                        >
                          Anterior
                        </button>
                      </li>
                      <li 
                        v-for="page in displayedPages" 
                        :key="page"
                        class="page-item"
                        :class="{ active: currentPage === page }"
                      >
                        <button 
                          v-if="page === '...'"
                          class="page-link"
                          disabled
                        >
                          {{ page }}
                        </button>
                        <button 
                          v-else
                          class="page-link"
                          @click="changePage(page)"
                        >
                          {{ page }}
                        </button>
                      </li>
                      <li 
                        class="page-item"
                        :class="{ disabled: currentPage === totalPages }"
                      >
                        <button 
                          class="page-link" 
                          @click="changePage(currentPage + 1)"
                          :disabled="currentPage === totalPages"
                        >
                          Siguiente
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import debounce from 'lodash/debounce';

export default {
  name: 'ListarOperaciones',
  
  setup() {
    // State
    const operations = ref([]);
    const users = ref([]);
    const loading = ref(false);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const totalOperations = ref(0);
    const errorMessage = ref('');
    const sortBy = ref('fecha');
    const sortDirection = ref('desc');

    // Filters
    const filters = ref({
      userId: '',
      status: '',
      startDate: '',
      endDate: ''
    });

    // Computed
    const isAdmin = computed(() => 
      localStorage.getItem('userRole') === 'admin'
    );

    const sortedOperations = computed(() => {
      return [...operations.value].sort((a, b) => {
        const aValue = a[sortBy.value];
        const bValue = b[sortBy.value];
        
        switch(sortBy.value) {
          case 'fecha':
            return sortDirection.value === 'asc' 
              ? new Date(aValue) - new Date(bValue)
              : new Date(bValue) - new Date(aValue);
          case 'importe':
            return sortDirection.value === 'asc' 
              ? aValue - bValue
              : bValue - aValue;
          default:
            return sortDirection.value === 'asc'
              ? aValue.toString().localeCompare(bValue.toString())
              : bValue.toString().localeCompare(aValue.toString());
        }
      });
    });

    const totalPages = computed(() => 
      Math.ceil(totalOperations.value / itemsPerPage.value)
    );

    const displayedPages = computed(() => {
      const delta = 2;
      const range = [];
      const rangeWithDots = [];
      let l;

      for (let i = 1; i <= totalPages.value; i++) {
        if (
          i === 1 || 
          i === totalPages.value || 
          i >= currentPage.value - delta && 
          i <= currentPage.value + delta
        ) {
          range.push(i);
        }
      }

      range.forEach(i => {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      });

      return rangeWithDots;
    });

    // Methods
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/users`, {
          credentials: 'include'
        });
        const data = await response.json();
        users.value = data.users;
      } catch (error) {
        console.error('Error fetching users:', error);
        errorMessage.value = 'Error al cargar usuarios';
      }
    };

    const fetchOperations = async () => {
      loading.value = true;
      try {
        const params = new URLSearchParams({
          page: currentPage.value,
          itemsPerPage: itemsPerPage.value,
          ...filters.value
        });

        const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones?${params}`, {
          credentials: 'include'
        });
        
        if (!response.ok) throw new Error('Error fetching operations');
        
        const data = await response.json();
        operations.value = data.operations;
        totalOperations.value = data.totalPages * itemsPerPage.value;
      } catch (error) {
        console.error('Error fetching operations:', error);
        errorMessage.value = 'Error al cargar operaciones';
      } finally {
        loading.value = false;
      }
    };

    const handleFilterChange = debounce(() => {
      currentPage.value = 1;
      fetchOperations();
    }, 300);

    const handlePageSizeChange = () => {
      currentPage.value = 1;
      fetchOperations();
    };

    const changePage = (page) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
        fetchOperations();
      }
    };

    const handleSort = (column) => {
      if (sortBy.value === column) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortBy.value = column;
        sortDirection.value = 'asc';
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    const formatAmount = (amount) => {
      return amount.toFixed(2);
    };

    const getStatusClass = (status) => {
      return status === 'open' ? 'text-success' : 'text-secondary';
    };

    // Lifecycle
    onMounted(async () => {
      if (isAdmin.value) {
        await fetchUsers();
      }
      await fetchOperations();
    });

    // Watchers
    watch([filters, itemsPerPage], handleFilterChange);

    return {
      operations,
      users,
      loading,
      currentPage,
      itemsPerPage,
      totalOperations,
      errorMessage,
      filters,
      isAdmin,
      sortedOperations,
      totalPages,
      displayedPages,
      sortBy,
      sortDirection,
      handleFilterChange,
      handlePageSizeChange,
      changePage,
      handleSort,
      formatDate,
      formatAmount,
      getStatusClass
    };
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

.list-group-item {
  background-color: #dddddd;
  border-color: #000;
}

.pagination button {
  margin: 5px;
}

.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
}

.card-body {
  padding: 20px;
}

.resultados {
  margin-bottom: 0.25rem;
}

.page-link {
  cursor: pointer;
}

.page-link:disabled {
  cursor: default;
}

.sort-header {
  cursor: pointer;
  user-select: none;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.sort-header:hover {
  background-color: rgba(0,0,0,0.05);
}

.sort-header.active {
  font-weight: bold;
  color: #d54e92;
}

.sort-indicator {
  display: inline-block;
  margin-left: 4px;
}

.gap-3 {
  gap: 1rem;
}
</style>