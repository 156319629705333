<template>
  <div class="datoscliente">
    <h3>Resumen</h3>
    <p><strong>Usuario:</strong> {{ userId }}</p>
    <p><strong>Número de operaciones abiertas:</strong> {{ numOperations }}</p>
    <p><strong>Monto total:</strong> ${{ formatAmount(totalAmount) }}</p>
    
    <!-- Commission Rate Section -->
    <div class="commission-section">
      <p>
        <strong>Comisión por operación:</strong>
        <span v-if="!isEditing">
          {{ localCommissionRate > 0 ? `$${formatAmount(localCommissionRate)}` : 'Sin comisión' }}
        </span>
        <input
          v-else
          type="number"
          v-model.number="newCommissionRate"
          step="0.01"
          min="0"
          class="commission-input"
        >
        <button v-if="isAdmin && !isEditing" class="btn-edit" @click="startEdit">Editar</button>
      </p>
      
      <div v-if="isEditing" class="edit-controls">
        <button class="btn-small" @click="saveCommissionRate">Guardar</button>
        <button class="btn-small btn-cancel" @click="cancelEdit">Cancelar</button>
      </div>
    </div>
    
    <!-- Only show commission totals if there is a commission rate -->
    <template v-if="localCommissionRate > 0">
      <p><strong>Monto total por comisiones:</strong> ${{ formatAmount(calculatedCommissionAmount) }}</p>
      <p><strong>Monto a rendir:</strong> ${{ formatAmount(calculatedRemainingAmount) }}</p>
    </template>
    <template v-else>
      <p><strong>Monto a rendir:</strong> ${{ formatAmount(totalAmount) }}</p>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    userId: {
      type: String,
      required: true
    },
    numOperations: {
      type: Number,
      required: false,
      default: 0
    },
    totalAmount: {
      type: Number,
      required: false,
      default: 0
    },
    commissionAmount: {
      type: Number,
      required: false,
      default: 0
    },
    commissionRate: {
    type: Number,
    required: false,
    default: 0
   }
  },
  data() {
    return {
      isEditing: false,
      newCommissionRate: 0,
      isAdmin: false,
      localCommissionRate: 0
    };
  },
  computed: {
    calculatedCommissionAmount() {
      return this.numOperations * this.localCommissionRate;
    },
    calculatedRemainingAmount() {
      return this.totalAmount - this.calculatedCommissionAmount;
    }
  },
  watch: {
    commissionRate: {
      immediate: true,
      handler(newValue) {
        this.localCommissionRate = newValue;
        this.newCommissionRate = newValue;
      }
    }
  },
  methods: {
    formatAmount(value) {
      return parseFloat(value).toFixed(2);
    },
    startEdit() {
      this.newCommissionRate = this.localCommissionRate;
      this.isEditing = true;
    },
    async saveCommissionRate() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/operaciones/commission`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'user-role': localStorage.getItem('userRole')
          },
          credentials: 'include',
          body: JSON.stringify({
            userid: this.userId,
            commissionRate: this.newCommissionRate
          })
        });

        if (response.ok) {
          const data = await response.json();
          this.localCommissionRate = parseFloat(data.commissionRate);
          this.isEditing = false;
          this.$emit('commission-updated');
        } else {
          console.error('Failed to update commission rate');
        }
      } catch (error) {
        console.error('Error updating commission rate:', error);
      }
    },
    cancelEdit() {
      this.isEditing = false;
      this.newCommissionRate = this.localCommissionRate;
    }
  },
  mounted() {
    this.isAdmin = localStorage.getItem('userRole') === 'admin';
    this.localCommissionRate = this.commissionRate;
  }
};
</script>

<style scoped>
.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
  margin-top: 10px;
}

.commission-section {
  margin: 10px 0;
}

.commission-input {
  width: 80px;
  padding: 4px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-edit {
  padding: 2px 8px;
  margin-left: 8px;
  background-color: #d54e92;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-controls {
  margin-top: 5px;
  display: flex;
  gap: 8px;
}

.btn-small {
  padding: 4px 8px;
  font-size: 0.9rem;
  border-radius: 4px;
  background-color: #d54e92;
  color: white;
  border: none;
  cursor: pointer;
}

.btn-cancel {
  background-color: #6c757d;
}
</style>