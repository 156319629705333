export class VersionService {
    static #currentVersion = process.env.VUE_APP_VERSION?.version || '1.2.0';
    static #apiUrl = process.env.VUE_APP_API_URL;

    /**
     * Initialize the version service
     */
    static async initialize() {
        try {
            console.log('Initializing version service with version:', this.#currentVersion);
            return await this.checkVersion('initialization');
        } catch (error) {
            console.error('Version service initialization failed:', error);
            return true; // Fail open for initialization
        }
    }

    /**
     * Check version against server
     */
    static async checkVersion(operationType = 'general') {
        try {
            const response = await fetch(`${this.#apiUrl}/version`, {
                headers: { 
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/json'
                },
                credentials: 'include' 
            });
            
            if (!response.ok) {
                console.warn(`Version check failed for ${operationType}. Continuing...`);
                return true; // Fail open
            }
            
            const serverVersion = await response.json();
            
            if (serverVersion.version !== this.#currentVersion) {
                await this.handleVersionMismatch(operationType);
                return false;
            }
            
            return true;
        } catch (error) {
            console.error(`Version check error (${operationType}):`, error);
            return true; // Fail open for version issues
        }
    }

    /**
     * Handle version mismatch
     */
    static async handleVersionMismatch(operationType) {
        const inPaymentFlow = ['payment', 'confirmation'].includes(operationType);
        
        // Preserve critical data
        const criticalData = {
            apiKeyIspcube: localStorage.getItem('apiKeyIspcube'),
            apiTokenIspcube: localStorage.getItem('apiTokenIspcube'),
            apiKeyPuntosdepago: localStorage.getItem('apiKeyPuntosdepago'),
            userRole: localStorage.getItem('userRole'),
            userid: localStorage.getItem('userid'),
            userName: localStorage.getItem('userName')
        };

        // If in payment flow, store additional data
        if (inPaymentFlow) {
            Object.assign(criticalData, {
                clienteNombre: localStorage.getItem('clienteNombre'),
                clienteDeuda: localStorage.getItem('clienteDeuda'),
                clienteId: localStorage.getItem('clienteId')
            });
        }

        alert('Se detectó una actualización. La página se actualizará automáticamente.');

        // Clear storage but preserve critical data
        localStorage.clear();
        sessionStorage.clear();

        // Restore critical data
        Object.entries(criticalData).forEach(([key, value]) => {
            if (value) localStorage.setItem(key, value);
        });

        // Set redirect flag if in payment flow
        if (inPaymentFlow) {
            sessionStorage.setItem('redirectToHome', 'true');
        }

        // Reload page
        setTimeout(() => window.location.reload(true), 500);
    }

    /**
     * Get version info
     */
    static getVersionInfo() {
        return { version: this.#currentVersion };
    }

    /**
     * Simple version comparison
     */
    static async versionsMatch() {
        try {
            const response = await fetch(`${this.#apiUrl}/version`, {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            });

            if (!response.ok) return true; // Fail open on check failure

            const serverVersion = await response.json();
            return serverVersion.version === this.#currentVersion;
        } catch (error) {
            console.error('Version match check failed:', error);
            return true; // Fail open on errors
        }
    }
}