<template>
  <div class="dashboard-container">
    <div class="alert alert-danger" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="row">
      <!-- Left Column: User Selection and Summary -->
      <div class="col-md-6">
        <!-- Admin Only: User Selection -->
        <div v-if="isAdmin" class="user-selection datoscliente">
          <h3>Seleccionar Usuario</h3>
          <label for="user-select">Usuario:</label>
          <select 
            v-model="selectedUserId" 
            @change="fetchDashboardData" 
            id="user-select" 
            class="form-control"
            :disabled="processing"
          >
            <option value="">Selecciona un usuario</option>
            <option 
              v-for="user in users" 
              :key="user.userid" 
              :value="user.userid"
            >
              {{ user.nombre }}
            </option>
          </select>
        </div>

        <!-- ResumenCierreCaja Component -->
        <ResumenCierreCaja
          :userId="selectedUserId || getUserId()"
          :numOperations="totalOpenOperations"
          :totalAmount="totalAmount"
          :commissionAmount="commissionAmount"
          :commissionRate="commissionRate"
          @commission-updated="fetchDashboardData"
        />
        
        <!-- Action Buttons -->
        <div class="btn-group">
          <button 
            class="btn-cancelar ml-2 rounded" 
            @click="goHome"
            :disabled="processing"
          >
            Inicio
          </button>
          <button 
            class="btn ml-2 rounded" 
            @click="confirmCloseOperations"
            :disabled="!canClose || processing"
          >
            {{ processing ? 'Procesando...' : 'Cerrar Caja' }}
          </button>
        </div>
      </div>

      <!-- Right Column: Last 10 Operations -->
      <div class="col-md-6">
        <div v-if="loading" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>
        
        <div v-else-if="lastOperations.length > 0" class="datoscliente">
          <h3>Últimas 10 Operaciones Abiertas</h3>
          <ul class="list-group mb-3">
            <li 
              v-for="operation in lastOperations" 
              :key="operation.idoperacion" 
              class="list-group-item d-flex justify-content-between lh-condensed"
            >
              <div>
                <h2 class="my-0">ID: {{ operation.idoperacion }}</h2>
                <small class="text-muted">
                  Monto: ${{ formatAmount(operation.importe) }} - 
                  Estado: {{ operation.status }} - 
                  Fecha: {{ formatDate(operation.fecha) }}
                </small>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <p>No hay operaciones disponibles.</p>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div v-if="showConfirmationModal" class="modal">
      <div class="modal-content">
        <h3 class="heading-3">Confirmar Cierre</h3>
        
        <ResumenCierreCaja
          :userId="selectedUserId || getUserId()"
          :numOperations="totalOpenOperations"
          :totalAmount="totalAmount"
          :commissionAmount="commissionAmount"
          :commissionRate="commissionRate"
          @commission-updated="fetchDashboardData"
        />

        <!-- Warning if no operations -->
        <div v-if="totalOpenOperations === 0" class="alert alert-warning">
          No hay operaciones abiertas para cerrar.
        </div>

        <!-- Buttons -->
        <div class="btn-group">
          <button 
            class="btn-cancelar" 
            @click="closeModal"
            :disabled="processing"
          >
            Cancelar
          </button>
          <button 
            class="btn" 
            @click="closeOperations"
            :disabled="totalOpenOperations === 0 || processing"
          >
            {{ processing ? 'Procesando...' : 'Confirmar' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResumenCierreCaja from '@/components/ResumenCierreCaja.vue';
import sessionMixin from '@/mixins/sessionMixin.js';
// Commented out for now - will review versioning later
// import { VersionService } from '@/services/VersionService';

export default {
  mixins: [sessionMixin],
  components: {
    ResumenCierreCaja
  },

  data() {
    return {
      totalAmount: 0,
      commissionAmount: 0,
      commissionRate: 0,
      totalOpenOperations: 0,
      lastOperations: [],
      errorMessage: '',
      showConfirmationModal: false,
      selectedUserId: '',
      users: [],
      loading: false,
      processing: false
    };
  },
  
  computed: {
    isAdmin() {
      return this.userRole === 'admin';
    },
    isLoggedIn() {
      return !!this.userRole;
    },
    canClose() {
      if (this.isAdmin) {
        return !!this.selectedUserId && this.totalOpenOperations > 0;
      }
      return this.totalOpenOperations > 0;
    }
  },

  methods: {
    getUserId() {
      return localStorage.getItem('userid');
    },

    goHome() {
      this.$router.push('/');
    },
    
    async fetchDashboardData() {
      const userId = this.isAdmin ? this.selectedUserId : this.getUserId();

      if (!userId) {
        this.errorMessage = this.isAdmin ? 
          'Por favor seleccione un usuario' : 
          'Falta el User ID. Por favor, inicie sesión de nuevo.';
        return;
      }

      try {
        this.loading = true;
        this.errorMessage = '';

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/operaciones/dashboard?userId=${userId}`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
          }
        );

        if (!response.ok) throw new Error('Error al obtener datos del dashboard');

        const data = await response.json();
        
        this.totalAmount = data.totalAmount ?? 0;
        this.commissionRate = data.commissionRate ?? 0;
        this.lastOperations = data.lastOperations?.filter(op => op.status === 'open') ?? [];
        this.totalOpenOperations = this.lastOperations.length;
        this.commissionAmount = this.totalOpenOperations * this.commissionRate;

        if (this.isAdmin && !this.users.length) {
          await this.fetchUsers();
        }

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },

    async closeOperations() {
      try {
        this.processing = true;
        this.errorMessage = '';

        const userid = this.isAdmin ? this.selectedUserId : this.getUserId();
        if (!userid) {
          throw new Error('ID de usuario faltante');
        }

        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/operaciones/closeOperations`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'user-role': this.userRole
            },
            credentials: 'include',
            body: JSON.stringify({ userid })
          }
        );

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Error al cerrar operaciones');
        }

        const result = await response.json();
        
        // Redirect to receipt with closure details
        this.$router.push({
          path: '/cierreDeCajaReceipt',
          query: {
            cierreId: result.cierreId,
            userId: userid,
            formattedDate: new Date().toLocaleString(),
            numOperations: result.numOperations,
            totalAmount: result.totalAmount,
            commissionAmount: result.commissionAmount
          }
        });

      } catch (error) {
        console.error('Error closing operations:', error);
        this.errorMessage = error.message;
        this.showConfirmationModal = false;
      } finally {
        this.processing = false;
      }
    },

    async confirmCloseOperations() {
      if (this.canClose) {
        this.showConfirmationModal = true;
      }
    },

    closeModal() {
      if (!this.processing) {
        this.showConfirmationModal = false;
      }
    },

    async fetchUsers() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/users`,
          {
            credentials: 'include'
          }
        );
        
        if (!response.ok) throw new Error('Error al obtener usuarios');
        
        const data = await response.json();
        this.users = data.users;
      } catch (error) {
        console.error('Error fetching users:', error);
        this.errorMessage = 'Error al cargar usuarios';
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },

    formatAmount(amount) {
      return Number(amount).toFixed(2);
    }
  },

  async mounted() {
    try {
      await this.fetchDashboardData();
      if (this.isAdmin) {
        await this.fetchUsers();
      }
    } catch (error) {
      console.error('Initialization error:', error);
      this.errorMessage = 'Error al inicializar. Por favor, recargue la página.';
    }
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

.datoscliente {
  background-color: #dddddd;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>