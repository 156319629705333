<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <img src="../img/iso-swissnet-512.png" alt="SwissNet" height="30">
    </div>
    <ul class="navbar-menu" v-if="isLoggedIn">
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('')">Inicio</button>
      </li>
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('seleccionaCliente')">Pagar</button>
      </li>
      <!-- Only show Listar Operaciones for admin -->
      <li v-if="isAdmin">
        <button class="btn-small mr-2 mb-2" @click="goTo('listarOperaciones')">Listar Operaciones</button>
      </li>
      <li>
        <button class="btn-small mr-2 mb-2" @click="goTo('cierreDeCaja')">Cierre de Caja</button>
      </li>
      <li v-if="isAdmin">
        <button class="btn-small mr-2 mb-2" @click="goTo('cierre-history')">
          Historial de Cierres
        </button>
      </li>
      <!-- Add Testing section for admin -->
      <li v-if="isAdmin">
        <div class="dropdown">
          <button class="btn-small mr-2 mb-2">Pruebas</button>
          <div class="dropdown-content">
            <button class="dropdown-item" @click="goTo('admin/payment-tests')">
              Pagos Tests
            </button>
            <button class="dropdown-item" @click="goTo('admin/cash-closing-tests')">
              Cierres Tests
            </button>
          </div>
        </div>

      </li>
      
    </ul>
    <div class="navbar-session" v-if="isLoggedIn">
      <span>{{ userName }}</span>
      <button class="btn-small mr-2 mb-2" @click="logout">Cerrar Sesión</button>
    </div>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

// Computed properties
const isLoggedIn = computed(() => {
  return localStorage.getItem('userRole') !== null;
});

const isAdmin = computed(() => {
  return localStorage.getItem('userRole') === 'admin';
});

const userName = computed(() => {
  return localStorage.getItem('userName') || 'Usuario';
});

// Methods
const goTo = (path) => {
  router.push(`/${path}`);
};

const logout = async () => {
  try {
    // Clear local storage
    localStorage.clear();
    sessionStorage.clear();
    
    // Redirect to login
    await router.push('/login');
  } catch (error) {
    console.error('Logout error:', error);
  }
};
</script>

<style scoped>
/* Your existing styles remain the same */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  z-index: 1;
  border-radius: 4px;
  margin-top: 2px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-item {
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #ffffffbd;
  border-bottom: 1px solid #ddd;
}

.navbar-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  margin-right: 1rem;
}

.navbar-session {
  display: flex;
  align-items: center;
}

.navbar-session span {
  margin-right: 10px;
}

button {
  cursor: pointer;
  background-color: #d54e92;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.btn-small {
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
}
</style>