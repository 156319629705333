<template>
  <div class="d-flex flex-column h-100 text-center">
    <main class="container">
      <p><br></p>
      <div class="starter-template text-center py-1 px-3">  
        <div class="card text-center">                      
          <div class="card-body">            
            <div class="row">
              <div class="col">
                <h5 class="card-title">Pagar Deuda</h5>
              </div>

              <div class="col datoscliente align-left">
                <p>Cliente: <strong>{{ clienteNombre }}</strong></p>
                <p>Total pendiente: <strong>{{ formattedTotalPendiente }}</strong></p>
                
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <p class="mb-0">Monto a pagar</p>
                  <div v-if="ENABLE_AMOUNT_EDIT" class="form-check form-switch">
                    <input 
                      class="form-check-input" 
                      type="checkbox" 
                      id="flexSwitchCheckDefault" 
                      v-model="isEditable"
                    >
                    <label class="form-check-label" for="flexSwitchCheckDefault">
                      {{ isEditable ? 'Editable' : 'Fijo' }}
                    </label>
                  </div>
                </div>

                <template v-if="isEditable && ENABLE_AMOUNT_EDIT">
                  <input 
                    type="number" 
                    v-model.number="totalDepositado" 
                    :style="{ color: deudaColor }"
                    class="form-control"
                    step="0.01"
                    min="0.01"
                    :max="Math.abs(clienteDeuda)"
                  />
                </template>
                <template v-else>
                  <div 
                    class="form-control text-start"
                    :style="{ color: deudaColor }"
                  >
                    {{ formattedDeuda }}
                  </div>
                </template>
              </div>
            </div>
            <br /><br />

            <div class="btn-group">
              <button class="btn-cancelar mr-2 rounded" @click="goHome">
                <strong>&nbsp;&nbsp;CANCELAR&nbsp;&nbsp;</strong>
              </button>
              <button 
                class="btn ml-2 rounded" 
                @click="setAmountAndGoTo('pagoRealizado')"
                :disabled="!isValidAmount">
                <strong>&nbsp;&nbsp;PAGAR&nbsp;&nbsp;</strong>
              </button>
            </div>
          </div>        
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const ENABLE_AMOUNT_EDIT = true;
//import { VersionService } from '@/services/VersionService';

export default {
  data() {
    return {
      ENABLE_AMOUNT_EDIT,
      clienteNombre: localStorage.getItem('clienteNombre') || '',
      clienteDeuda: parseFloat(localStorage.getItem('clienteDeuda')) || 0,
      totalDepositado: 0,
      deudaColor: '',
      isEditable: false,
      errorMessage: ''
    };
  },
  computed: {
    formattedDeuda() {
      return `$ ${this.totalDepositado.toFixed(2)}`;
    },
    formattedTotalPendiente() {
      return `$ ${this.clienteDeuda.toFixed(2)}`;
    },
    isValidAmount() {
      const amount = parseFloat(this.totalDepositado);
      return amount > 0 && amount <= Math.abs(this.clienteDeuda);
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    updateUI() {
      this.totalDepositado = Math.abs(this.clienteDeuda);
      this.deudaColor = this.clienteDeuda >= 0 ? 'blue' : 'red';
    },
    async setAmountAndGoTo(route) {
      if (!this.isValidAmount) return;

      const amount = parseFloat(this.totalDepositado).toFixed(2);
      localStorage.setItem('totalDepositado', amount);
      this.$router.push(`/${route}`);
    }
  },
  async mounted() {
    if (!localStorage.getItem('clienteNombre') || !localStorage.getItem('clienteDeuda')) {
      alert('Información del cliente no disponible. Volviendo al inicio...');
      this.$router.push('/');
      return;
    }
    this.updateUI();
  }
};
</script>

<style scoped>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '@/css/starter-template.css';
@import '@/css/estilosCompartidos.css';

.form-switch {
  padding-left: 2.5em;
}

.form-check-input {
  cursor: pointer;
}
</style>